import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/constants/AppConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';
import { sha512 } from 'js-sha512';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

	show_patient: boolean = false;
	no_patient: boolean = false;
	showRegister: boolean = false;
	hideSearch: boolean = true;
	error: boolean = false;
	errorMsg = "";
	showpinmsg = "";
	mobilevalue = "";
	submitted = false;
	patientExist: Array<any>;
	registerPatient: FormGroup;
	appoinmentForm: FormGroup;
	fname;
	phone1;
	demail;
	lname;
	lastname;
	msg = '';
	hide: boolean = false;
	errMsgSize: boolean = false;
	errMsgType: boolean = false;
	UploadImages = [];
	files = [];
	imgURL = [];
	selectedIdentity: String = 'select';
	identitylist = [];
	identity_id = '';
	hosp_name = sessionStorage.getItem("hosp_name");
	remaining = 100;
	length;
	fee;
	paymentGateways: Array<any> = [];
	public payuform: any = {};
	orderDetails: Array<any>;
	disablePaymentButton: boolean = true;
	url: SafeResourceUrl;
	paymentEnabled: boolean = false;
	source;
	receiveMessage: EventListener;
	appDetails;
	complaintDetails;
	identityCardDetails;
	patientDetails;
	newRegDetails: any;
	showMsg;
	showError;
	requestUrl: String = "";
	constructor(
		public router: Router,
		private _http: HttpService,
		private hostElement: ElementRef,
		private sanitizer: DomSanitizer,
		private _fb: FormBuilder) {
		this.listenForFallbackRoutingEvents();
		this.registerPatient = this._fb.group({
			fname: ['', [Validators.required]],
			lname: ['', [Validators.required]],
			gender: ['male', [Validators.required]],
			age: ['', [Validators.required, Validators.max(110)]],
			email: ['', Validators.email],
			mrn: [''],
			fee: [''],
			symptoms: ['']
		})
		const patientJSON = sessionStorage.getItem('newReg');
		const newReg = JSON.parse(patientJSON);
		this.fname = newReg.name || "";
		this.phone1 = sessionStorage.getItem('cid') ? sessionStorage.getItem('cid') : '';
		this.demail = newReg.demail ? newReg.demail : '';
		//	alert(parseInt(sessionStorage.getItem("length")))
		this.length = parseInt(sessionStorage.getItem("length"), 10)
		//	alert(length)
		if (parseInt(sessionStorage.getItem("length")) > 0) {
		//	this.registerPatient.controls.fname.setValue(sessionStorage.getItem("fname"));
			if (sessionStorage.getItem("lname") !== "null") {
		//		this.registerPatient.controls.lname.setValue(sessionStorage.getItem("lname"))
			}
		}
		this.fee = sessionStorage.getItem("fee");
		setTimeout(function () {
			let viewheight = (window as any).visualViewport.height;
			let viewwidth = (window as any).visualViewport.width;
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0,maximum-scale=1.0, user-scalable=no, minimal-ui");
		}, 300);
	}

	async ngOnInit() {
		const patientJSON = sessionStorage.getItem('newReg');
		const newReg = JSON.parse(patientJSON);
		this.phone1 = sessionStorage.getItem('cid') ? sessionStorage.getItem('cid') : '';
		if (newReg && newReg.new_reg) {
			this.registerPatient.patchValue({
				fname: newReg.fname ? newReg.fname : '',
				lname: newReg.lname ? newReg.lname : '',
				phone1: sessionStorage.getItem('cid') ? sessionStorage.getItem('cid') : ''
			})
			this.lastname = newReg.lname === null ? false : true;
			console.log(this.lastname)
		}
		const iframe = this.hostElement.nativeElement.querySelector('iframe');
		console.log(iframe)

		const appoinmentJson = sessionStorage.getItem('booked_appoinment');
		this.appDetails = JSON.parse(appoinmentJson);
		console.log("Payment Details -- ", this.appDetails);
		const timeStamp = Date.now().toString();
		console.log(sha512('HXvbeheyyefhd+7yfxcdrue' + '|' +
			sessionStorage.getItem('orderDetails') + '|' +
			this.appDetails.cid + '|' + this.appDetails.amount + '|' +
			timeStamp.slice(0, timeStamp.length - 3)));


		await this.showPaymentGateways();

		const newRegJson = sessionStorage.getItem('newReg');
		this.newRegDetails = JSON.parse(newRegJson);
		if (this.appDetails && isNullOrUndefined(this.appDetails.amount)) {
			this.appDetails.amount = this.newRegDetails.amount
			console.log("Payment Details -- ", this.appDetails.amount);
		}
		console.log("Payment Details -- ", this.newRegDetails);

		const regPatientJSON = sessionStorage.getItem('reg_patientDetail');
		const exitsPat = sessionStorage.getItem('patientDetail');
		if (regPatientJSON) {
			this.patientDetails = JSON.parse(regPatientJSON);
			console.log("patient Details registered -- ", this.patientDetails);
		} else if (exitsPat) {
			this.patientDetails = JSON.parse(exitsPat);
			console.log("existing patient Details -- ", this.patientDetails);
		}
	}
	listenForFallbackRoutingEvents() {
		// Create IE + others compatible event handler (2)
		const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
		const eventer = window[eventMethod];
		const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

		eventer(messageEvent, (e) => {
			console.log("page redirects to --- ", e.data.navigateTo);
			if (e.data.navigateTo) {
				let url = e.data.navigateTo;
				console.log("page redirects to --- ", e.data.navigateTo);
				//this.router.navigateByUrl(url); (4)
			}
		}, false);
	}
	onLoadFunc(paymentpage) {
		console.log("onLoadFunc called");
		//console.log('iframe link ************** ' , paymentpage.contentDocument.location.href);
		this.source = paymentpage.contentWindow.location.href;
		//console.log("iframe content document ",paymentpage.contentDocument.body );
		console.log("payment page link is : ", this.source);
	}
	showPaymentGateways() {
		this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.PAYMENT_GATEWAYS).then((result: any) => {
			if (result) {
				console.log("payment gateway --- ", result)
				const gateways = result.mh ? (result.mh[0] ? result.mh[0].payment : []) : [];
				if (gateways && gateways.length > 0) {
					for (let index = 0; index < gateways.length; index++) {
						const element = gateways[index];
						// if (element.vendor_name == "Paytm") {
							this.paymentGateways.push(element);
							this.requestUrl = this.paymentGateways[index].request_url;
							console.log("this.request_url ", this.requestUrl)
						// }
					}
					return;
				}
				if (!this.paymentGateways || this.paymentGateways.length == 0) {
					//this.router.navigate(['/bookappointment']);
				}
			}
		})
	}
	getPaymentGateway(gate) {
		console.log("Get result of gateway --- ", gate);
		this.requestUrl = gate.request_url;
		console.log("this.requestUrl = ", this.requestUrl)
	}
	getPaymentGateway1(gate, event) {
		console.log("Get result of gateway --- ", gate, event);
		if (event === true) {
			this.requestUrl = gate.request_url;
			console.log("this.requestUrl = ", this.requestUrl)
		}
	}
	navigate() {
		this.getPaymentGateway(this.paymentGateways[0]);
		console.log(this.paymentGateways[0]);
		console.log(this.registerPatient.value);
//		alert(this.registerPatient.value)
		const patientJSON = sessionStorage.getItem('newReg');
		const newReg = JSON.parse(patientJSON);
		this.submitted = true;
		if(this.registerPatient.valid)
		{
			const value = this.registerPatient.getRawValue();
			value['mode'] = AppConstants.APPOINTMENT.MODE_DATE;
			value['fname'] = this.fname ? this.fname : value.fname;
			value['lname'] = this.lname ? this.lname : value.lname;
			value['phone1'] = this.phone1 ? this.phone1 : value.phone1;
			value['demail'] = AppConstants.COMMON_DMAIL;
			const regDataJSON = sessionStorage.getItem('newReg');
			const regDetails: any = JSON.parse(regDataJSON);
			value['demail'] = AppConstants.COMMON_DMAIL;
			value['gender'] = value.gender;
			value['doctor_id'] = newReg.doctor_id;
			value['weight'] = "null";
			value['father'] = "null";
			value['email'] = value.email;
			value['age'] = value.age;
			value['mother'] = "null";
			value['mrn'] = value.mrn;
			value['phone2'] = "null";
			value['symptoms'] = value.symptoms;
			value['edate'] = moment(new Date()).format("YYYY-MM-DD");
			value['eyear'] = "null";
			var d = new Date();
			var year = d.getFullYear();
			var month = d.getMonth();
			var day = d.getDate();
			value['dob'] = moment(new Date(year - value.dob, month, day)).format('YYYY-MM-DD')
			regDetails['dob'] = moment(new Date(year - value.dob, month, day)).format('YYYY-MM-DD')
			console.log("value --- ", value);
			console.log("regDetails --- ", regDetails);
			var pemail;
			value['new_patient'] = true;
			sessionStorage.setItem('reg_patientDetail', JSON.stringify(value))
			const patientJSON1 = sessionStorage.getItem('booked_appoinment');
			const patientDetail = JSON.parse(patientJSON1);
			if (patientDetail) {
				console.log("patientDetail  -  ", patientDetail)
				sessionStorage.setItem('thankyou', JSON.stringify(patientDetail));
				this.createOrder();
			} 
			else 
			{
				
			}
		}
	}
	
	createOrder() {
		const timeStamp = Date.now().toString();
		const ip = sessionStorage.getItem("local_ip");
		console.log('timeStamp --- ', timeStamp.slice(0, timeStamp.length - 3))
		let time = timeStamp.slice(0, timeStamp.length - 3)
		sessionStorage.setItem('timeStamp', time);
		const complaintJson = sessionStorage.getItem('appoint_complaint_details');
		const complaintDetails = JSON.parse(complaintJson);
		console.log("complaintDetails Details -- ", complaintDetails);
		const identityJson = sessionStorage.getItem('appoint_identity_details');
		const identityDetails = JSON.parse(identityJson);
		console.log("identityDetails Details -- ", identityDetails);
		const params = {
			"mobile": this.appDetails.cid, //cus _mmob
			"total": this.appDetails.amount, // book_a
			"mode": AppConstants.APPOINTMENT.MODE_DATE,
			"timestamp": time,
			"details": "Video Call booking appointment for patient Name: " + this.patientDetails.full_name + " - MRID : " + this.appDetails.mrid,
			"session": ip, // Booking Appointment ID
			"pdate": this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '' 	// appoinment Id		
		}
		if (this.appDetails.new_patient === false) {
			// let Order_Param = {
			// 	mode: 1,
			// 	mobile: this.appDetails.cid,
			// 	total: this.appDetails.amount,
			// 	timestamp: time,
			// 	details: "p001X1,p002X2",
			// 	session: ip,
			// 	pdate: this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '', 	// appoinment Id		
			// 	mr_id: this.appDetails.mrid,
			// 	patient_email: this.appDetails.pemail,
			// 	demail: this.appDetails.demail,
			// 	doctor_id: this.appDetails.doctor_id,
			// 	patient_name: this.patientDetails.full_name,
			// 	symptoms: this.registerPatient.value.symptoms,
			// 	mrn:this.registerPatient.value.mrn,
			// 	age:this.patientDetails.age,
			// 	booking_time: this.appDetails.btime,
			// 	token: this.appDetails.btoken,
			// 	event: this.appDetails.event,
			// 	a_id: this.appDetails.a_id,
			// 	user_type: 0,
			// 	ref_id: "",
			// 	complaint_attach: complaintDetails?String(complaintDetails.compliant_img):""
			// }
			// console.log("Order_Param Book_patient ", Order_Param)
			// this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.ORDER_URL_3_1, Order_Param).then((result: any) => {
			// 	if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
			// 		console.log("Order Details -- ", result.mh[0].success)
			// 		this.orderDetails = result.mh[0].orderid ? result.mh[0].orderid : "";
			// 		sessionStorage.setItem('orderDetails', this.orderDetails.toString())
			// 		this.redirectToPayment();
			// 	}
			// });
			var d = new Date();
			var year = d.getFullYear();
			var month = d.getMonth();
			var day = d.getDate();
			this.patientDetails.dob= moment(new Date(year - this.registerPatient.value.age, month, day)).format('YYYY-MM-DD')
			let new_reg_params = {
				demail : this.appDetails.demail,
				fname : this.registerPatient.value.fname,
				lname : this.registerPatient.value.lname?this.registerPatient.value.lname:"",
				gender : this.registerPatient.value.gender,
				dob : this.patientDetails.dob,
				address : this.patientDetails.address?"":"",
				father : "",
				mother : "",
				phone1 : sessionStorage.getItem("cid"),
				email : this.registerPatient.value.email?this.registerPatient.value.email:'',
				timestamp: time,
				identity_attach: identityDetails?identityDetails.identity_img:"",
				identity_id: identityDetails?identityDetails.identity_id:""
			}
			let Order_Param = {
				mode: 1,
				mobile: this.appDetails.cid,
				total: this.appDetails.amount,
				timestamp: time,
				details: "p001X1,p002X2",
				session: ip,
				patient_name: this.registerPatient.value.fname+this.registerPatient.value.lname,
				pdate: this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '', 	// appoinment Id		
				mr_id: "",
				patient_email: "",
				demail: this.appDetails.demail,
				doctor_id: this.appDetails.doctor_id,
				booking_time: this.appDetails.btime,
				token: this.appDetails.btoken,
				event: this.appDetails.event,
				symptoms: this.registerPatient.value.symptoms,
				mrn:this.registerPatient.value.mrn,
				a_id: this.appDetails.a_id,
				user_type: 1,
				ref_id: "",
				complaint_attach: complaintDetails?String(complaintDetails.compliant_img):""
			}
			this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.REGISTRATION_URL_3_1, new_reg_params).then((result: any) => {
				if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
					console.log("Registered New patient Details -- ", result.mh[0].success)
					Order_Param['ref_id']= result.mh[0].ref_id;
					this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.ORDER_URL_3_1, Order_Param).then((result: any) => {
						if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
							console.log("Order Details -- ", result.mh[0].success)
							this.orderDetails = result.mh[0].orderid ? result.mh[0].orderid : "";
							sessionStorage.setItem('orderDetails', this.orderDetails.toString())
							this.appDetails.mrid="";
							this.redirectToPayment();
						}
					});
				}
			});
		} 
		else {
			var d = new Date();
			var year = d.getFullYear();
			var month = d.getMonth();
			var day = d.getDate();
			this.patientDetails.dob= moment(new Date(year - this.registerPatient.value.age, month, day)).format('YYYY-MM-DD')
			let new_reg_params = {
				demail : this.appDetails.demail,
				fname : this.registerPatient.value.fname,
				lname : this.registerPatient.value.lname?this.registerPatient.value.lname:"",
				gender : this.registerPatient.value.gender,
				dob : this.patientDetails.dob,
				address : this.patientDetails.address?"":"",
				father : "",
				mother : "",
				phone1 : this.patientDetails.cid,
				email : this.registerPatient.value.email?this.registerPatient.value.email:'',
				timestamp: time,
				identity_attach: identityDetails?identityDetails.identity_img:"",
				identity_id: identityDetails?identityDetails.identity_id:""
			}
			console.log(new_reg_params)
			let Order_Param = {
				mode: 1,
				mobile: this.appDetails.cid,
				total: this.appDetails.amount,
				timestamp: time,
				details: "p001X1,p002X2",
				session: ip,
				patient_name: this.registerPatient.value.fname+this.registerPatient.value.lname,
				pdate: this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '', 	// appoinment Id		
				mr_id: "",
				patient_email: "",
				demail: this.appDetails.demail,
				doctor_id: this.appDetails.doctor_id,
				booking_time: this.appDetails.btime,
				token: this.appDetails.btoken,
				event: this.appDetails.event,
				symptoms: this.registerPatient.value.symptoms,
				mrn:this.registerPatient.value.mrn,
				a_id: this.appDetails.a_id,
				user_type: 1,
				ref_id: "",
				complaint_attach: complaintDetails?String(complaintDetails.compliant_img):""
			}
			console.log("new_reg_params Book_patient ", new_reg_params)
			console.log("Order_Param Book_patient ", Order_Param)
			this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.REGISTRATION_URL_3_1, new_reg_params).then((result: any) => {
				if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
					console.log("Registered New patient Details -- ", result.mh[0].success)
					Order_Param['ref_id']= result.mh[0].ref_id;
					this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.ORDER_URL_3_1, Order_Param).then((result: any) => {
						if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
							console.log("Order Details -- ", result.mh[0].success)
							this.orderDetails = result.mh[0].orderid ? result.mh[0].orderid : "";
							sessionStorage.setItem('orderDetails', this.orderDetails.toString())
							this.appDetails.mrid="";
							this.redirectToPayment();
						}
					});
				}
			});
		}
	}

	redirectToPayment() {
		const orderid = sessionStorage.getItem('orderDetails');
		let transactionID = this.appDetails.mrid + this.appDetails.sid;
		// alert(transactionID)
		let orderID = orderid;
		let amount = this.appDetails.amount;
		// let amount = "1";
		let bName = this.patientDetails.full_name;
		let bAddress = "";
		let bCity = '';
		let bState = '';
		let bZip = "";
		let bCountry = 'India';
		let bMobile = this.appDetails.cid;
		let bEmail = this.appDetails.demail;
		let paytmData = {
			mobile: bMobile,
			amount: amount,
			email: bEmail,
			order_id: orderID,
			timestamp:sessionStorage.getItem('timeStamp')
		}
		const timeStamp = Date.now().toString();

		let params = {
			trID: this.appDetails.mrid + this.appDetails.sid,
			oID: orderid,
			key: sha512('HXvbeheyyefhd+7yfxcdrue' + '|' + sessionStorage.getItem('orderDetails') + '|' + this.appDetails.cid + '|' + parseInt(this.appDetails.amount) + '|' + sessionStorage.getItem('timeStamp')),
			b_name: this.patientDetails.full_name,
			b_address: "",
			b_city: "",
			b_state: "",
			b_zip: "",
			b_country: 'India',
			b_tel: this.appDetails.cid,
			b_email: this.appDetails.demail
		}
	//	alert(this.appDetails.mrid);
		sessionStorage.setItem('paytm_data', JSON.stringify(paytmData))
	//	alert(transactionID)
		let payTmlink = this.requestUrl + '?trID=' + transactionID + '&oID=' + orderID + '&key=' + sha512('HXvbeheyyefhd+7yfxcdrue' + '|' + sessionStorage.getItem('orderDetails') + '|' + this.appDetails.cid + '|' + parseInt(this.appDetails.amount) + '|' + sessionStorage.getItem('timeStamp')) + '&b_name=' + bName + '&b_address=' + bAddress + '&b_city=' + bCity + '&b_state=' + bState + '&b_zip=' + bZip + '&b_country=' + bCountry + '&b_tel=' + bMobile + '&b_email=' + bEmail;

		window.open(payTmlink, "_parent");
	}

	update() {
		//alert("hi");
	}

	keyPress(event) {
		// const pattern = /[0-9\ ]/;
		const pattern = /^\d+$/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar) || event.keyCode == 32) {
			event.preventDefault();
		}
	}

	omit_special_char(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
	}
	email_char(event) {
		//alert("hi")
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57) || k == 64 || k == 46 || k == 95);
	}
}