import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/constants/AppConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss']
})

export class AppointmentListComponent implements OnInit {
	mobileno = "";
	date  = null;
	appointments: Array<any>;
	constructor(public router: Router, private _http: HttpService, private _fb : FormBuilder) { }

  	ngOnInit() {
		const appointmentJson = sessionStorage.getItem(AppConstants.SESSION_KEY.APPOINTMENT_LIST);
		if(appointmentJson) {
			this.appointments = JSON.parse(appointmentJson);
			console.log(this.appointments)
		}
	  }
	  
	  getAppointStauts(slot) {
		const currentDate = new Date();
		let eventDateFrmt = new Date(slot)
		console.log(eventDateFrmt.getTime(), currentDate.getTime())
		let futureAppointmentExits = eventDateFrmt.getTime() > currentDate.getTime();
		return futureAppointmentExits;
	  }
}
