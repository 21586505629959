import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { BookappointmentModule } from './bookappointment/bookappointment.module'
import { ThankyouModule } from './thankyou/thankyou.module'
import { PaymentModule } from './payment/payment.module'
import { RegisterModule } from './register/register.module'
import { AppointmentModule } from './appointment/appointment.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FindpageModule } from './findpage/findpage.module'
import { FailureComponent } from './failure/failure.component';
import { FindpageComponent } from './findpage/findpage.component';
const routes: Routes = [
	// {
	// 	path: 'findpage',
	// 	loadChildren: './findpage/findpage.module#FindpageModule'
	// },
	{
		path: 'findpage',
		component:FindpageComponent
		//loadChildren:() => FindpageModule
		// loadChildren: './findpage/findpage.module#FindpageModule'
	},
	{
		path: 'bookappointment',
		loadChildren:() => BookappointmentModule
		// loadChildren: './bookappointment/bookappointment.module#BookappointmentModule'
	},
	{
		path: 'bookappointment/:mobile_number/:p_name/:mr_number',
		loadChildren:() => BookappointmentModule
		// loadChildren: './bookappointment/bookappointment.module#BookappointmentModule'
	},
	{
		path: 'thankyou',
		loadChildren:() => ThankyouModule
		// loadChildren: './thankyou/thankyou.module#ThankyouModule'
	},
	{
		path: 'payment',
		loadChildren:() => PaymentModule
	},
	{
		path: 'register',
		loadChildren:() => RegisterModule
	},
	{
		path: 'appointment',
		loadChildren:() => AppointmentModule
	},
	{
		path:'failure',
		component:FailureComponent
	},
	{
		path: '',
		component: FindpageComponent
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes),  BrowserModule,BrowserAnimationsModule],
	exports: [RouterModule]
})

export class LayoutRoutingModule { }
