import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from './layout/layout.module';

const routes: Routes = [
  {
		path: '',
		loadChildren:() => LayoutModule
		// loadChildren: './findpage/findpage.module#FindpageModule'
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule,LayoutModule]
})

export class AppRoutingModule { }
