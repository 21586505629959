import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentComponent } from "./payment.component"
import { MyDatePickerModule } from 'mydatepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatInputModule, MatStepperModule } from '@angular/material';


@NgModule({
	declarations: [
		PaymentComponent
	],
	imports: [
		CommonModule,
		MyDatePickerModule,
		PaymentRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule
	]
})

export class PaymentModule { }
