import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { IMyDpOptions, IMyDateModel, IMyDate, IMyOptions } from 'mydatepicker';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/constants/AppConstants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContent } from './bookterms.component';
import * as moment from 'moment';
import { log } from 'util';
import * as _ from 'lodash';
import { MatStepper } from '@angular/material';
import { PlatformLocation } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker'
import { unset } from 'lodash';
export const MY_FORMATS = {
	parse: {
		dateInput: "LL"
	},
	display: {
		dateInput: "DD-MMM-YY",
		monthYearLabel: "YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "YYYY"
	}
};


export interface DialogData {
	doctors_name: Array<Object>
}

@Component({
	selector: 'app-bookappointment',
	templateUrl: './bookappointment.component.html',
	styleUrls: ['./bookappointment.component.scss'],
	providers: [
		// `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
		// application's root module. We provide it at the component level here, due to limitations of
		// our example generation script.
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],

})

export class BookappointmentComponent implements OnInit {
	selectedDate: IMyDate = { year: 0, month: 0, day: 0 };
	public mytime: Date = new Date();
	docname;
	currentYear: any = this.mytime.getFullYear();
	currentDate: any = this.mytime.getDate() - 1;
	currentMonth: any = Number(moment(new Date()).format('MM'));
	UploadImages = [];
	files = [];
	imgURL = [];
	a_id = '';

	today = new Date();
	isLinear = true;
	public myDatePickerOptions: IMyDpOptions = {
		disableDateRanges: [
			{
				begin: { year: 1900, month: 1, day: 1 },
				end: { year: 2200, month: 12, day: 31 }
			}
		],
		dateFormat: 'dd-mm-yyyy',
		showClearDateBtn: false,
		enableDays: []
	};
	msg;
	showMsg: boolean = false;
	showError: boolean = false;
	submitted = false;
	doctors_name = [];
	// [{ 'display_name': 'A', 'doctor_id': '1', 'doctor_email': 'doctor.a@gmail.com' }, { 'display_name': 'B', 'doctor_id': '2', 'doctor_email': 'doctor.b@gmail.com' }, { 'display_name': 'C', 'doctor_id': '3', 'doctor_email': 'doctor.c@gmail.com' }, { 'display_name': 'D', 'doctor_id': '4', 'doctor_email': 'doctor.d@gmail.com' }, { 'display_name': 'E', 'doctor_id': '5', 'doctor_email': 'doctor.e@gmail.com' }];

	doctorsFilter_name = [];
	hospital_name = "Dr. Agarwal's Eye Hospital"
	time_list: Array<any>;
	doc_id = '';
	doc_email;
	doc_name = '';
	b_date;
	ddate;
	demail;
	starttime;
	start_time;
	end_time;
	timeArray = [];
	appoitmentTime = "";
	appoitmentTimeobj;
	paymen_details = [];
	location;
	languagelist = [];
	arrLang = [];
	arrLen;
	selectedlanguage: String = 'Preferred Consultation Language';
	countrylist = [];
	selectedCountry: String = 'select';
	locationlist = [];
	selectedLocation: String = 'select';
	selectedType: String = 'select'
	selectedMode: String = 'select'
	statelist = [];
	selectedstate: String = 'select';
	languagelistFilter = [];
	statelistFilter = [];
	citylistFilter = [];
	locationlistFilter = [];
	country_id = "";
	state_id = "";
	city_id = "";
	location_id = "";
	pincode_id = "";
	language_id = "";
	citylist = [];
	selectedCity: String = 'select';
	selecteddoctor: String = 'select';
	selectedtime: String = 'select';
	doctorObj;
	img_url = environment.doc_image_url;
	tName = "";
	speciality = ["Ophthalmologist", "Peadictrics"];
	appoinment;
	appoinment_type_str: String = "Tele-Clarification";
	amountLabel = "Amount to Pay"
	d_event;
	mrid;
	appoinment_type = [
		{
			"name": "Teleconsultation",
			"value": "teleconsultation"
		},
		{
			"name": "In-person",
			"value": "in_person"
		},
	]
	appoinment_mode = [
		// {
		// 	"name": "Voice Call",
		// 	"type": "voice_call"
		// },
		// {
		// 	"name": "Video Call",
		// 	"type": "emergency_video_call_appointment_fee"
		// },
	]
	doc_charge;
	token = ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1", "K1", "L1", "M1", "N1"];
	btoken;
	app_mode;
	email;
	array = [];
	appoinmentForm: FormGroup;
	public model: any = { date: { year: 2018, month: 10, day: 9 } };
	operator: any;
	selectedDoc;
	patientName: String = "";
	fname = "";
	lname = "";
	full_name = "";
	phone1;
	minDate;
	maxDate;
	agreeValue: boolean = false;
	errMsgSize: boolean = false;
	errMsgType: boolean = false;
	showLang: boolean = false;
	showSpinner: boolean = false;
	last;
	errorMsg;
	locations = [];
	locChosen = false;
	@ViewChild('stepper') stepper: MatStepper;

	appointmentFormControl;
	constructor(public router: Router,
		private atp: AmazingTimePickerService,
		private _route: ActivatedRoute,
		// toasterService: ToasterService,
		private modalService: NgbModal,
		private location1: PlatformLocation,
		private _http: HttpService,
		public dialog: MatDialog,
		public dialog1: MatDialog,
		public dialog2: MatDialog,
		private _fb: FormBuilder) {
			
		let d: Date = new Date();

		this.selectedDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
		this._http.post(environment.Loc_URL, AppConstants.COUNTRY_Params).then((result: any) => {
			this.countrylist = result.mh.response;
			this.countrylist = result.mh.response;
			this.countrylist.sort(function (a, b) {
				return a.country < b.country ? -1 : a.country > b.country ? 1 : 0;
			});
		})
		this.demail = AppConstants.COMMON_DMAIL;
		this.setDefaultValues();
		this.enableDays();
		var minCurrentDate = new Date();
		var maxNewDate = new Date();
		this.minDate = minCurrentDate;
		var date = new Date();
		this.last = new Date(date.getTime() + (6 * 24 * 60 * 60 * 1000));
		this.maxDate = maxNewDate.setMonth(maxNewDate.getMonth() + 1);
		this._http.get(environment.BASE_URL + '/doctorlist.php' + '?demail=' + AppConstants.COMMON_DMAIL).then((result: any) => {
			this.doctorObj = result;
			this.doctors_name = this.doctorObj.doctors[0].doctor_list;
			for (var i = 0; i < this.doctors_name.length; i++) {
				if (!this.locations.includes(this.doctors_name[i].city))
					this.locations.push(this.doctors_name[i].city)
			}
			//this.doctors_name=[];
		})
		
	}
	private modalRef: NgbModalRef;

	openDoctors(content) {
		this.docname = "Doctor " + this.selecteddoctor;
		this.modalService.open(content).result.then((result) => {
		});
	}
	openDialog(): void {
		if (this.locChosen) {
			const dialogRef = this.dialog.open(DialogDoctors, {
				data: { doctors_name: this.doctors_name, url: this.img_url },
				backdropClass: 'backdropBackground'
			});
			dialogRef.afterClosed().subscribe(result => {
				// this.animal = result;
				this.docname = result.display_name;
				this.selecteddoctor = result.display_name;
				this.appoinmentForm.controls['doc_name'].setValue(this.docname);
				this.doc_name = result.display_name;
				this.doc_id = result.doctor_id;
				this.doc_email = result.doctor_email;
				this.appoinmentForm.controls['doc_name'].setValue(this.docname);
				const paramsPay = {
					demail: AppConstants.COMMON_DMAIL,
					doctor_id: this.doc_id,
				}
				this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.PAY_URL + '?demail=' + paramsPay.demail + '&doctor_id=' + paramsPay.doctor_id).then((result: any) => {
					this.paymen_details = result.mh[0].settings;
					if (this.paymen_details) {
						this.getAppointmentMode(null)
					}
				})
				this.onDateChanged(null);
			});
		}
	}

	openDialog1(): void {
			const dialogRef1 = this.dialog1.open(Dialog1, {
				width: '250px',
				backdropClass: 'backdropBackground'
			});
			dialogRef1.afterClosed().subscribe(result => {
			})
	}

	openDialog2(): void {
		const dialogRef2 = this.dialog2.open(Dialog2, {
			width: '250px',
			backdropClass: 'backdropBackground'
		});
		dialogRef2.afterClosed().subscribe(result => {
		})
}

	locationChange(val) {
		this.locChosen = true;
		this.appoinmentForm.controls.doc_name.setValue("")
		var docs = [];
		this.doctors_name = this.doctorObj.doctors[0].doctor_list
		for (var i = 0; i < this.doctors_name.length; i++) {
			if (this.doctors_name[i].city == this.appoinmentForm.controls.location.value) {
				docs.push(this.doctors_name[i])
			}
		}
		this.doctors_name = docs;
	}


	chooseDoc(name, id, email) {
		this.docname = name;
		this.selecteddoctor = name;
		this.appoinmentForm.controls['doc_name'].setValue(this.docname);
		this.doc_name = name;
		this.doc_id = id;
		this.doc_email = email;
		this.onDateChanged(null);
		const paramsPay = {
			demail: this.demail,
			doctor_id: this.doc_id,
		}
		this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.PAY_URL + '?demail=' + paramsPay.demail + '&doctor_id=' + paramsPay.doctor_id).then((result: any) => {
			this.paymen_details = result.mh[0].settings;
			if (this.paymen_details) {
				this.getAppointmentMode(null)
			}
		})

	}

	navigate() {
		this.submitted = true;
		if (this.appoinmentForm.valid) {
			this.onDateChanged(null)
			console.log(this.timeArray)
			const patientJSON = sessionStorage.getItem('patientDetail');
			const patientDetail = JSON.parse(patientJSON);
			this.mrid = patientDetail.mr_number;
			let params = {
				demail: AppConstants.COMMON_DMAIL,
				cid: sessionStorage.getItem("cid"),
				btoken: this.btoken,
				btime: this.appoitmentTime + "Z",
				event: this.app_mode = "video_call_appointment_fee" ? "video_call" : "",
				sid: Date.now(),
				cid_e164: sessionStorage.getItem("cid"),
				edate: moment(new Date()).format("YYYY-MM-DD"),
				pemail: this.email,
				mrid: this.mrid,
				doctor_id: this.doc_id,
				a_id: this.a_id,
				operator: sessionStorage.getItem('local_ip')
			}
			localStorage.setItem('dr_name', this.doc_name);
			sessionStorage.setItem("fname", this.fname);
			sessionStorage.setItem("lname", this.lname)
			if (patientDetail && patientDetail.patient_exists) {
				params['amount'] = sessionStorage.getItem("fee");
				params['new_patient'] = false;
				sessionStorage.setItem('newReg', JSON.stringify(params));
				sessionStorage.setItem('booked_appoinment', JSON.stringify(params));
				sessionStorage.removeItem('reg_patientDetail');
				sessionStorage.removeItem('reg_patientDetail');
				var full_name = patientDetail.full_name;
				this.getName(full_name)
			} else {
				params['new_reg'] = true;
				params['cid'] = sessionStorage.getItem('cid');
				params['event'] = this.app_mode = "video_call_appointment_fee" ? "video_call" : "";
				params['edate'] = moment(new Date()).format("YYYY-MM-DD");
				params['fname'] = this.fname;
				params['lname'] = this.lname;
				params['cid_e164'] = sessionStorage.getItem('cid');
				params['btoken'] = this.btoken;
				params['doctor_id'] = params.doctor_id;
				params['amount'] = sessionStorage.getItem("fee");
				params['operator'] = sessionStorage.getItem('local_ip');
				params['new_patient'] = true;
				sessionStorage.setItem('newReg', JSON.stringify(params));
				sessionStorage.setItem('booked_appoinment', JSON.stringify(params));
				sessionStorage.removeItem('reg_patientDetail');
				sessionStorage.setItem("visited", "true");
			}
			var time = new Date().getTime();
		var d = new Date(time);
		var currentHour = d.getHours();
		var today = moment(new Date()).format("DD");
		var selDate = moment(this.appoinmentForm.controls.date.value).format("DD");
		var flag;
		if (today < selDate) {
			flag = true;
		}
		else {
			flag = false;
		}
		var x = document.getElementById("snackbar");
		if ((currentHour >= 16) && today == selDate) {
			x.className = "show";
			this.errorMsg="Appointment cannot be booked after 4 PM for a particular day"
		//	setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
		//	this.openDialog1();
	//	alert("Appointment cannot be booked after 4 PM for a particular day");
		}
		else if(this.timeArray.length==0)
		{
			x.className = "show";
			this.errorMsg="Doctor has no available slots for the selected date"
		//	setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	//	alert("Doctor has no available slots for the selected date");
		//	this.openDialog2();
		}
		else {
			this.router.navigate(['/register']);
		}
		}
	}

	closeAlert(){
	//	alert("hi")
		 var x = document.getElementById("snackbar");
		 setTimeout(function(){ x.className = x.className.replace("show", ""); }, 500);
		}

	changeMode() {
		if (this.appoinmentForm.value.appoinment_type == "teleconsultation") {
			this.appoinment_mode = [
				{
					'name': 'Voice call',
					'value': 'voice_call'
				},
				{
					'name': 'Video call',
					'value': 'video_call_appointment_fee'
				}
			]
		}
		else {
			this.appoinment_mode = [
				{
					'name': 'Appointment',
					'value': 'appointment'
				},
				{
					'name': 'Vaccination',
					'value': 'vaccination'
				}
			]
		}
	}
	// Enable next four dates from today
	enableDays() {
		let enableDays: Array<any> = [];
		for (let i = 0; i < 7; i++) {
			let d: Date = new Date();
			d.setDate(d.getDate() + i);
			enableDays.push({
				year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()
			});
		}

		let copy = this.getCopyOfOptions();
		copy.enableDays = enableDays;
		this.myDatePickerOptions = copy;
	}

	// Returns copy of myOptions
	getCopyOfOptions(): IMyOptions {
		return JSON.parse(JSON.stringify(this.myDatePickerOptions));
	}

	open() {
		const modalRef = this.modalService.open(NgbdModalContent);
		// modalRef.componentInstance.lesson = lesson;
	}

	getlanguage(val, id, type) {
		this.arrLen = this.arrLang.length;
		let index = _.findIndex(this.arrLang, { 'language': val });
		if (_.findIndex(this.arrLang, { 'language': val }) !== -1) {
			this.arrLang.splice(index, 1);
		} else {
			this.arrLang.push({
				'language': val,
				'type': id
				// 'type': booo
			})
		}

		var value = this.languagelist.filter(a => a.language === val);
		value[0].checked = type;
		var a = this.arrLang.lastIndexOf(",");
		this.showLang = false;
		this.language();
	}

	language() {
		this.language_id = '';
		for (let i = 0; i < this.languagelist.length; i++) {
			if (this.languagelist[i].checked === true) {
				if (this.language_id != '')
					this.language_id += ',' + this.languagelist[i].language_id;
				else
					this.language_id = this.languagelist[i].language_id;
			}
		}
		AppConstants.DOCTOR_Params["param"] = "state:" + this.state_id + "&country:" + this.country_id + "&location:" + this.city_id + "&language:" + this.language_id + "&pincode:" + this.pincode_id;
		this._http.post(environment.Loc_URL, AppConstants.DOCTOR_Params).then((result: any) => {
			if (result.mh.response != "unable to pull details") {
				this.doctors_name = result.mh.response;
				this.doctors_name.sort(function (a, b) {
					return a.display_name < b.display_name ? -1 : a.display_name > b.display_name ? 1 : 0;
				});

				this.selectedDoc = this.doctors_name[0].display_name;
				this.doc_id = this.doctors_name[0].doctor_id;
				this.doc_name = this.doctors_name[0].display_name;
			} else {
				this.selectedDoc = 'select';
			}
		})

	}

	showLanguage() {
		if (!this.showLang) {
			this.showLang = true;
			// this.arrLang = [];
		}
		else {
			this.showLang = false;
			this.language();
		}
	}

	setDefaultValues() {
		var location = 'Chennai';
		this._http.getIpAddress();
	}

	getCountry(val) {
		if (val == 'select') {
			return;
		}
		var value = val;
		var cntry = this.countrylist.filter(a => a.country === value);
		this.country_id = cntry[0].country_id;
		this.citylist = [];
		this.selectedCity = 'select';
		this.locationlist = [];
		this.selectedLocation = 'select';
		this.statelist = [];
		this.selectedstate = 'select';
		this.doctors_name = [];
		this.doc_name = '';
		this.selecteddoctor = 'select';
		this.arrLang = [];
		this.timeArray = [];
		this.languagelist = [];
		this.appoitmentTimeobj = 'select';
		this.showLang = false;
		AppConstants.STATE_Params["country"] = cntry[0].country_id;
		this._http.post(environment.Loc_URL, AppConstants.STATE_Params).then((result: any) => {
			this.statelistFilter = result.mh.response;
			this.statelistFilter.sort(function (a, b) {
				return a.state < b.state ? -1 : a.state > b.state ? 1 : 0;
			});
			this.statelist = this.statelistFilter;
		})

	}

	getState(val) {
		if (val === 'select') {
			return;
		}
		this.selectedstate = val;
		var cntry = this.statelist.filter(a => a.state === val);
		this.state_id = cntry[0].state_id;
		AppConstants.CITY_Params["state"] = cntry[0].state_id;
		this.citylist = [];
		this.selectedCity = 'select';
		this.locationlist = [];
		this.selectedLocation = 'select';
		this.doctors_name = [];
		this.doc_name = '';
		this.selecteddoctor = 'select';
		this.arrLang = [];
		this.timeArray = [];
		this.languagelist = [];
		this.appoitmentTimeobj = 'select';
		this.showLang = false;
		this._http.post(environment.Loc_URL, AppConstants.CITY_Params).then((result: any) => {
			this.citylistFilter = result.mh.response;
			this.citylistFilter.sort(function (a, b) {
				return a.location < b.location ? -1 : a.location > b.location ? 1 : 0;
			});
			this.citylist = this.citylistFilter;
		})

	}

	getCity(val) {
		if (val == 'select') {
			return;
		}
		this.selectedCity = val;
		var cntry = this.citylist.filter(a => a.location === val);
		this.city_id = cntry[0].location_id;
		AppConstants.LOC_Params["location"] = cntry[0].location_id;
		this.locationlist = [];
		this.doctors_name = [];
		this.doc_name = '';
		this.arrLang = [];
		this.showLang = false;
		this.timeArray = [];
		this.languagelist = [];
		this.appoitmentTimeobj = 'select';
		this.selecteddoctor = 'select';
		this.selectedLocation = 'select';
		this._http.post(environment.Loc_URL, AppConstants.LOC_Params).then((result: any) => {
			this.locationlistFilter = result.mh.response;
			this.locationlistFilter.sort(function (a, b) {
				return a.pincode < b.pincode ? -1 : a.pincode > b.pincode ? 1 : 0;
			});
			this.locationlist = this.locationlistFilter;
		})

	}

	getLocation(val) {
		if (val == 'select') {
			return;
		}


		this.selectedLocation = val;
		var cntry = this.locationlist.filter(a => a.pincode === val);
		this.doctors_name = [];
		this.doc_name = '';
		this.selecteddoctor = 'select';
		this.pincode_id = cntry[0].pincode_id;
		this.timeArray = [];
		this.languagelist = [];
		this.appoitmentTimeobj = 'select';
		this.arrLang = [];
		this.showLang = false;

		AppConstants.LANGG_Params["param"] = "country:" + this.country_id + "&location:" + this.city_id + "&state:" + this.state_id + "&pincode:" + this.pincode_id;
		this._http.post(environment.Loc_URL, AppConstants.LANGG_Params).then((result: any) => {
			this.languagelistFilter = result.mh.response;
			this.languagelistFilter.sort(function (a, b) {
				return a.language < b.language ? -1 : a.language > b.language ? 1 : 0;
			});
			// this.languagelist = this.languagelistFilter;
			let list = this.languagelistFilter;
			let valuefilter = list.filter((res: any) => {
				res = Object.assign(res, { checked: false })
				return res;
			})
			this.languagelist = valuefilter;
		})

		AppConstants.DOCTOR_Params["param"] = "state:" + this.state_id + "&country:" + this.country_id + "&location:" + this.city_id + "&pincode:" + this.pincode_id;

		this._http.post(environment.Loc_URL, AppConstants.DOCTOR_Params).then((result: any) => {
			if (result.mh.response != "unable to pull details") {
				this.doctors_name = result.mh.response;
				this.doctors_name.sort(function (a, b) {
					return a.display_name < b.display_name ? -1 : a.display_name > b.display_name ? 1 : 0;
				});

				this.selectedDoc = this.doctors_name[0].display_name;
				this.doc_id = this.doctors_name[0].doctor_id;
				this.doc_name = this.doctors_name[0].display_name;
			} else {
				this.selectedDoc = 'select';
			}
		})
	}
	getDoctorName() {
		let val = this.selectedDoc;
		if (val == 'select') {
			return;
		}
		var d_name = val;
		var filter = this.doctors_name.filter(filterRes => filterRes.display_name === d_name);
		this.doc_id = filter[0].doctor_id;
		this.doc_email = filter[0].doctor_email;
		this.doc_name = filter[0].display_name;
		// this.selecteddoctor = '';
		this.onDateChanged(null)
		const paramsPay = {
			demail: AppConstants.COMMON_DMAIL,
			doctor_id: this.doc_id,
		}
		this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.PAY_URL + '?demail=' + paramsPay.demail + '&doctor_id=' + paramsPay.doctor_id).then((result: any) => {
			this.paymen_details = result.mh[0].settings;
			if (this.paymen_details) {
				this.getAppointmentMode(null)
			}
		})
	}

	getTimeValue() {
		this.appoitmentTimeobj = this.timeArray[this.timeArray.length - 1];
		if (this.appoitmentTimeobj != 'select') {
			this.btoken = this.appoitmentTimeobj.token;
			this.appoitmentTime = this.appoitmentTimeobj.start;
			this.a_id = this.appoitmentTimeobj.id;
			sessionStorage.setItem('slotTime', this.appoitmentTimeobj.slotTime)
		}
	}

	getAppointmentMode(val) {
		var filter = this.paymen_details.filter(res => res.module === "video_call_appointment_fee")
		var consultFee = this.paymen_details.filter(res => res.module === "video_call_consultation")
		if (filter && filter.length > 0 && consultFee && consultFee.length > 0) {
			const feeAmount = parseInt(filter[0].doctor_charge) + parseInt(consultFee[0].doctor_charge)
			// this.appoinmentForm.controls.amount
			// 	.setValue((feeAmount ? feeAmount : 0) + ".00");
			this.amountLabel = String(feeAmount)
			this.doc_charge = filter[0].doctor_charge + ".00";
			this.app_mode = filter[0].module;
			sessionStorage.setItem("fee", this.amountLabel)
		} else {
			this.doc_charge = "0.00"
		}
	}

	ngOnInit() {
		this.buildForm();
		const patientJSON = sessionStorage.getItem('patientDetail');
		const patientDetail = JSON.parse(patientJSON);
		if (patientDetail.patient_exists) {
			this.appoinmentForm.patchValue({
				name: patientDetail.full_name,
				mrid: patientDetail.mr_number,
				cid: patientDetail.father_tel_number,
				cid_e164: patientDetail.father_tel_number,
				pemail: patientDetail.father_email
			})
			this.mrid = patientDetail.mr_number;
			this.phone1 = patientDetail.father_tel_number;
			this.email = patientDetail.email;
		}
		if (!patientDetail.patient_exists) {
			this.appoinmentForm.patchValue({
				cid: patientDetail.cid,
				cid_e164: patientDetail.cid,
			})
			this.phone1 = patientDetail.cid;
		}
	}
	keyPress(event) {
		const pattern = /^[a-zA-Z ]*$/;
		let regex = pattern.test(event.target.value);
		if (!regex) {
			event.preventDefault();
		}
		// let inputChar = String.fromCharCode(event.charCode);
		if (!(event.keyCode >= 65 && event.keyCode <= 90) && !(event.keyCode >= 97 && event.keyCode <= 122) && (event.keyCode != 32 && event.keyCode != 0)) {
			event.preventDefault();
		}
	}

	getName(event) {
		let eee = event.trim();
		if (eee.indexOf(' ') != -1) {
			var name1 = eee.split(" ");
			var lastword = name1.pop();
			this.fname = name1.join(' ');
			const lostArr = eee.split(' ').splice(-1);
			this.lname = lostArr[0];
			this.full_name = this.fname + " " + this.lname;
			//	this.appoinmentForm.controls.name.setValue(this.fname + " " + this.lname);
		} else {
			this.fname = eee;
			this.lname = null;
		}
		sessionStorage.setItem("fname", this.fname);
		sessionStorage.setItem("lname", this.lname);
	}

	buildForm() {
		this.appoinmentForm = this._fb.group({
			location: ["", Validators.required],
			date: [moment(this.minDate), Validators.required],
			doc_name: ['', [Validators.required]],
		})
	}

	checkBox(event) {
		this.agreeValue = event.currentTarget.checked;
	}

	clear() {
		this.errMsgSize = false;
		this.errMsgType = false;
	}

	GetFile(e) {
		this.errMsgSize = false;
		this.errMsgType = false;
		this.files = Array.from(e.target.files);

		if ((this.files[0].type === 'image/png')
			|| (this.files[0].type === 'image/jpg')
			|| (this.files[0].type === 'image/jpeg')
			|| (this.files[0].type === 'image/tif')
			|| (this.files[0].type === 'application/pdf')) {
			if (this.files[0].size > 10000000) {
				this.errMsgSize = true;
				this.errMsgType = false;
			} else {
				this.UploadImages.push(e.target.files)
				for (let i = 0; i < this.files.length; i++) {
					var reader = new FileReader();
					reader.readAsDataURL(this.files[i]);
					reader.onload = (_event) => {
						this.imgURL.push({
							url: reader.result,
							name: this.files[i].name,
							type: this.files[i].name.split('.')[this.files[i].name.split('.').length - 1]
						})
					}
				}
			}
		} else {
			this.errMsgType = true;
			this.errMsgSize = false;
		}
	}

	public removePicture(item, index) {
		this.imgURL.splice(index, 1);
		this.files.splice(index, 1);
		this.UploadImages.splice(index, 1);
	}

	bookappointment() {
		this.submitted = true;
		this.appoinmentForm.markAsTouched();
		if (this.appoinmentForm.invalid) {
			return;
		} else if ((this.appoitmentTimeobj === 'select') || (this.selecteddoctor === 'select')) {
			return;
		} else {
			this.submitted = false;
			this.showSpinner = true;
		}

		if (this.appoitmentTimeobj != 'select') {
			const formValues = Object.assign({}, this.appoinmentForm.value);

			var d1 = new Date(this.b_date);
			var d2 = new Date(this.b_date);
			d2.setMinutes(d1.getMinutes() - 330);

			let params = {
				demail: this.demail,
				cid: formValues.cid,
				event: this.app_mode = "video_call_appointment_fee" ? "video_call" : "",
				btime: this.appoitmentTime + "Z",
				sid: Date.now(),
				cid_e164: formValues.cid,
				btoken: this.btoken,
				edate: moment(new Date()).format("YYYY-MM-DD"),
				pemail: this.email,
				mrid: this.mrid,
				doctor_id: this.doc_id,
				a_id: this.a_id,
				operator: sessionStorage.getItem('local_ip')
			}

			localStorage.setItem('dr_name', this.doc_name);
			localStorage.setItem('pt_name', formValues.name);
			let complaint_params = {
				account_email: this.demail,
				app_id: '',
				doctor_id: this.doc_id,
				patient_email: this.email,
				compliant_img: []
			}

			var arr = Array.from(this.UploadImages)
			if (arr.length != 0 || arr.length != undefined) {
				for (let i = 0; i < arr.length; i++) {

					Object.keys(arr[i]).forEach(key => {

						if ((arr[i][key].type === "image/png") ||
							(arr[i][key].type === "image/jpg") ||
							(arr[i][key].type === "image/jpeg")) {


							const formData = new FormData();
							formData.append('photo', arr[i][key]);
							let body = formData;
							this._http.post(environment.image_url, body).then((result: any) => {
								if (result.image.length > 0) {
									if (result.image[0]['success'] === 1) {
										complaint_params['compliant_img'].push(result.image[0]['filename'])
										sessionStorage.setItem('appoint_complaint_details', JSON.stringify(complaint_params));
									}
								}
							})
						} else if ((arr[i][key].type === "application/pdf")) {
							const formData = new FormData();
							formData.append('pdf', arr[i][key]);
							let body = formData;
							this._http.post(environment.pdf_url, body).then((result: any) => {
								if (result.pdf.length > 0) {
									if (result.pdf[0]['success'] === 1) {
										complaint_params['compliant_img'].push(result.pdf[0]['filename'])
										sessionStorage.setItem('appoint_complaint_details', JSON.stringify(complaint_params));
									}
								}
							})
						}
					})
				}

			}

			const patientJSON = sessionStorage.getItem('patientDetail');
			const patientDetail = JSON.parse(patientJSON);
			if (patientDetail && patientDetail.patient_exists) {
				params['amount'] = formValues.amount;
				params['new_patient'] = false;
				params['fname'] = this.fname;
				params['lname'] = this.lname;
				sessionStorage.setItem('booked_appoinment', JSON.stringify(params));
				sessionStorage.removeItem('reg_patientDetail');
				sessionStorage.removeItem('newReg');
				sessionStorage.removeItem('reg_patientDetail');
				setTimeout(() => {
					this.showSpinner = false;
					this.router.navigate(['/payment']);
				}, 5000);


			} else {
				params['new_reg'] = true;
				params['cid'] = sessionStorage.getItem('cid');
				params['event'] = this.app_mode = "video_call_appointment_fee" ? "video_call" : "";
				params['edate'] = moment(new Date()).format("YYYY-MM-DD");
				params['fname'] = this.fname;
				params['lname'] = this.lname;
				params['cid_e164'] = sessionStorage.getItem('cid');
				params['doctor_id'] = params.doctor_id;
				params['amount'] = formValues.amount;
				params['operator'] = sessionStorage.getItem('local_ip');
				params['btoken'] = this.btoken;
				params['btime'] = this.appoitmentTime + "Z";
				params['new_patient'] = true;
				sessionStorage.setItem('newReg', JSON.stringify(params));
				sessionStorage.setItem('booked_appoinment', JSON.stringify(params));
				sessionStorage.removeItem('reg_patientDetail');
				setTimeout(() => {
					this.showSpinner = false;
					this.router.navigate(['/register']);
				}, 5000);
				sessionStorage.setItem("visited", "true")
			}
		}
	}

	onDateChanged(event: IMyDateModel) {
		if (this.doc_name != '')
			this.showSpinner = true;
		var dateVal;
		if (event) {
			dateVal = moment(event.jsdate);
			this.ddate = moment(event.jsdate);
		} else {
			const selectedDate: any = this.appoinmentForm.controls.date.value
			dateVal = moment(selectedDate.jsdate)
			this.ddate = moment(selectedDate);
		}
		this.appoitmentTimeobj = 'select';
		this.b_date = moment(this.appoinmentForm.controls.date.value).format('YYYY-MM-DD')
//		alert(this.b_date)
		const params = {
			mode: AppConstants.TIME_SEARCH.MODE,
			demail: this.demail,
			doctor_id: parseInt(this.doc_id),
			bdate: this.b_date
		}
		this._http.post(environment.BASE_URL + environment.HTTP_CONFIG.REGISTRATION_URL, params).then((result: any) => {
			this.time_list = result.mh[0].slots.schedule_availability;
			let j;
			const multiDymArray = [];
			if (this.time_list != undefined) {
				const sortedArray = this.time_list.sort((a, b) => {
					let aDate = new Date(a.start + "Z");
					let bDate = new Date(b.start + "Z");
					a.correctedStart = aDate;
					b.correctedStart = bDate;
					let val = a.correctedStart - b.correctedStart;
					return val;
				});
				const availableTimeSlots = [];
				sortedArray.filter((value) => {
					const start = new Date(value.correctedStart);
					const currentDate = new Date();
					var d1 = moment(new Date()).format("DDMMYYYY");
					var d2 = moment(this.appoinmentForm.controls.date.value).format("DDMMYYYY");
					if (d1 == d2) {
						if (moment(start).valueOf() > moment(currentDate).valueOf()) {
							let slotTime;
							if (start.getHours() < 9) {
								slotTime = "0" + start.getHours() + ":00 - 0" + (start.getHours() + 1) + ":00";
							} else if (start.getHours() == 9) {
								slotTime = "0" + start.getHours() + ":00 - " + (start.getHours() + 1) + ":00";
							} else if (start.getHours() >= 10 && start.getHours() < 24) {
								slotTime = start.getHours() + ":00 - " + (start.getHours() + 1) + ":00";
							}
							value.slotTime = slotTime;
							value.correctedStart = value.correctedStart.toISOString();
							availableTimeSlots.push(value);
						}
					}
					else {
						let slotTime;
						if (start.getHours() < 9) {
							slotTime = "0" + start.getHours() + ":00 - 0" + (start.getHours() + 1) + ":00";
						} else if (start.getHours() == 9) {
							slotTime = "0" + start.getHours() + ":00 - " + (start.getHours() + 1) + ":00";
						} else if (start.getHours() >= 10 && start.getHours() < 24) {
							slotTime = start.getHours() + ":00 - " + (start.getHours() + 1) + ":00";
						}
						value.slotTime = slotTime;
						value.correctedStart = value.correctedStart.toISOString();
						availableTimeSlots.push(value);
					}
				});
				var non_duplidated_data = _.uniqBy(availableTimeSlots, 'slotTime');
				this.timeArray = non_duplidated_data;
				this.getTimeValue();
			}
			this.showSpinner = false;
		})
	}

	goback() {
		this.router.navigate(['./'])
	}

	get appoinmentFormControl() {
		return this.appoinmentForm.controls;
	}
}

@Component({
	selector: 'dialog-doctors',
	templateUrl: 'dialog-doctors.html',
	styleUrls: ['dialog-doctors.scss']
})

export class DialogDoctors {
	constructor(
		public dialogRef: MatDialogRef<DialogDoctors>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	onNoClick(): void {
		this.dialogRef.close();
	}
}

@Component({
	selector: 'dialog1',
	templateUrl: 'dialog1.html',
	styleUrls: ['dialog1.scss']
})

export class Dialog1 {

	constructor(
		public dialogRef2: MatDialogRef<Dialog1>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) 
		{ }

	onNoClick(): void {
		this.dialogRef2.close();
	}
}

@Component({
	selector: 'dialog2',
	templateUrl: 'dialog2.html',
	styleUrls: ['dialog2.scss']
})

export class Dialog2 {

	constructor(
		public dialogRef: MatDialogRef<Dialog2>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	onNoClick(): void {
		this.dialogRef.close();
	}
}



