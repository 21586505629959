import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentListComponent } from './components/appointment-list/appointment-list.component';
import { AppointmentDetailComponent } from './components/appointment-detail/appointment-detail.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'list'
	},
	{
		path : 'list' ,
		component : AppointmentListComponent
	},
	{
		path : 'detail' ,
		component : AppointmentDetailComponent
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AppointmentRoutingModule { }