import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  config;
  serverUrl;

  constructor(
    private http: HttpClient,
  ) {
  }
  public headername: BehaviorSubject<any> = new BehaviorSubject<any>({});

  setHeaderName(data) {
    console.log("header");
    console.log(data);
    this.headername.next(data);
  }

  public hideFooter: BehaviorSubject<any> = new BehaviorSubject<any>({});

  setHideFooter(data) {
    console.log("header");
    console.log(data);
    this.hideFooter.next({
      hide:data
    });
  }

  private static extractData(res: any) {
    return res || {};
  }

  private static handleError(error: any): Promise<any> {
    console.error(error);
    return Promise.reject(error.error || error);
  }
  getIpAddress() {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        console.log('th data', data);
        sessionStorage.setItem('local_ip', data.ip)
      })
  }

  async get(
    url: string,
    { params = {}, headers = {}, responseType }: { params?, headers?, responseType?} = { responseType: 'json' }) {
    return new Promise((resolve, reject) => {
      this.http
        .get(url, { params, headers, responseType })
        .toPromise()
        .then((response) => {
          resolve(HttpService.extractData(response));
        })
        .catch(err => {
          HttpService.handleError(err);
          reject(err);
        });
    });
  }

  async put(url: string, body = <any>{}, { headers = {}, params = {} }: { params?, headers?} = {}) {
    return new Promise((resolve, reject) => {
      this.http
        .put(url, body, { params, headers })
        .toPromise()
        .then((response) => {
          resolve(HttpService.extractData(response));
        })
        .catch(err => reject(err));
    });
  }

  // postForm(data): Observable<any> {
  //   return this.http.post('https://helyxon.com/kamaraj/mh/api/v1.2/reg.php', data)
  // }

  async post(url: string, body = <any>{}, { headers = {}, params = {} }: { params?, headers?} = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(url, body, { params, headers })
        .toPromise()
        .then((response) => {
          resolve(HttpService.extractData(response));
        })
        .catch(err => reject(err));
    });
  }

  async delete(url: string, { params = {}, headers = {} }: { params?, headers?} = {}) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(url, { params, headers })
        .toPromise()
        .then((response) => {
          resolve(HttpService.extractData(response));
        })
        .catch(err => reject(err));
    });
  }

  // async deleteWithBody(url: string, body = {} as any, {params = {}, headers = {}}: { params?, headers? } = {}) {
  //   const httpOptions = {
  //     headers, body
  //   };
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .delete(url, httpOptions)
  //       .toPromise()
  //       .then((response) => {
  //         resolve(HttpService.extractData(response));
  //       })
  //       .catch(err => reject(err));
  //   });
  // }

}
