import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/constants/AppConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { sha512 } from 'js-sha512';
import { MatStepper } from '@angular/material';


@Component({
	selector: 'app-findpage',
	templateUrl: './findpage.component.html',
	styleUrls: ['./findpage.component.scss']
})

export class FindpageComponent implements OnInit, AfterViewInit {
	show_patient: boolean = false;
	no_patient: boolean = false;
	showRegister: boolean = false;
	hideSearch: boolean = true;
	error: boolean = false;
	errorMsg = "";
	mobilevalue = "";
	submitted = false;
	patientExist: Array<any>;
	registerPatient: FormGroup;
	countries = [];
	isEditable = true;
	isLinear = true;
	selectedCountry: any;
	msg;
	hosp_name = sessionStorage.getItem("hosp_name");
	showdrop: boolean = false;
	currentYear = new Date().getFullYear();
	demail = sessionStorage.getItem("demail");
	@ViewChild("inputBox") _el: ElementRef;
	@ViewChild('stepper') stepper: MatStepper;

	constructor(
		public router: Router,
		private route: ActivatedRoute,
		private _http: HttpService,
		private _fb: FormBuilder) { }

	ngOnInit() {
		const hashKey = this.route.snapshot.queryParams['result'];

		if (hashKey) {

			const paytmDataStr = sessionStorage.getItem('paytm_data');
			let paytmData = JSON.parse(paytmDataStr);

			const generatedHash = sha512('HXvbeheyyefhd+7yfxcdrue' + '|' + paytmData.order_id + '|' + paytmData.mobile + '|' + paytmData.timestamp);

			if (hashKey == generatedHash) {

				this.router.navigate(["/thankyou"])
			} else {
				console.log("else");
				this.router.navigate(["/failure"])
			}
		} else {

		}

		this.buildForm();
		this._el.nativeElement.focus();
		console.log(this.currentYear);
		this._http.get('https://restcountries.eu/rest/v2/').then((result: any) => {
			var value = result;
			this.countries = value.filter(a =>
				((a.name === 'India') || (a.name === 'Kenya') ||
					(a.name === 'Madagascar') || (a.name === 'Mauritius') ||
					(a.name === 'Mozambique') || (a.name === 'Kenya') ||
					(a.name === 'Ghana') || (a.name === 'Nigeria') ||
					(a.name === 'Rwanda') || (a.name === 'Tanzania') ||
					(a.name === 'Uganda') || (a.name === 'Zambia'))
			);
			console.log('result countries --', this.countries);
			var filter = this.countries.filter(a => a.name === 'India');
			this.selectedCountry = filter[0];
			console.log('result countries --', this.selectedCountry);
		})
	}


	showCountry() {
		console.log('Country');
		if (!this.showdrop)
			this.showdrop = true;
		else
			this.showdrop = false;
	}

	getSelectedCountry(list, i) {
		console.log('Selected Country -- ', i, list);
		this.showdrop = false;
		this.selectedCountry = list;
	}

	buildForm() {
		this.registerPatient = this._fb.group({
			fname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
			gender: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
			dob: ['', [Validators.required]],
			phone1: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
		})
	}

	getAge(date) {
		const dd = new Date(date).getFullYear();
		return (this.currentYear - dd);
	}

	changenumber(num) {
		var regx = /^\d+$/;
		var text = num.toString();
		if (num.toString().length === 10&&regx.test(text)) {
			console.log('called');
			this.hideSearch = false;
			this.showPatient();
		} else {
			this.hideSearch = true;
			this.show_patient = false;
			this.msg = "Please Enter 10 digit mobile number"
		}
		if (!num) {
			this.show_patient = false;
			this.patientExist = []
			this.hideSearch = true;
		}
	}

	ngAfterViewInit() {
		this._el.nativeElement.focus();
		this.stepper._getIndicatorType = () => 'number';
		// this.stepper.selectedIndex = 1; 
	}

	clear() {
		this.mobilevalue = "";
		this.show_patient = false;
		this.hideSearch = true;
		this._el.nativeElement.focus();
	}

	showPatient() {
		console.log('called');
		if (this.mobilevalue.toString().length !== 10) {
			return;
		}
		this.showRegister = false;

		const params = {
			mode: AppConstants.NUMBER_SEARCH.MODE,
			demail: AppConstants.COMMON_DMAIL,
			cid: this.mobilevalue
		}
		if (this.mobilevalue) {
			this._http.post(environment.BASE_URL + environment.HTTP_CONFIG.REGISTRATION_URL, params).then((result: any) => {
				if (result) {
					console.log(result.mh[0].users[0])
					this.patientExist = result.mh ? (result.mh[0] ? result.mh[0].users : []) : [];
					if (this.patientExist && this.patientExist.length > 0) {
						this.show_patient = true;
						this.no_patient = false;
						//	alert("yes");
						var length = String(this.patientExist.length)
						sessionStorage.setItem("length", length);
						var patient = this.patientExist[0];
						console.log(patient)
						patient['patient_exists'] = true;
						sessionStorage.setItem('cid', this.mobilevalue)
						sessionStorage.setItem('patientDetail', JSON.stringify(patient))
						sessionStorage.setItem('reg_patientDetail', '')
						return;
					}
					if (!this.patientExist || this.patientExist.length == 0) {
						let patient = {};
						patient['patient_exists'] = false;
						patient['cid'] = this.mobilevalue;
						sessionStorage.setItem('cid', this.mobilevalue)
						sessionStorage.setItem('patientDetail', JSON.stringify(patient))
						sessionStorage.setItem("length", "0")
						//	alert("no");
					}
				}
			}).catch((error) => {
				console.log('catch error');
				console.log(error);
			})
		}
	}

	keyPress(event) {
		// const pattern = /[0-9\ ]/;
		const pattern = /^\d+$/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar) || event.keyCode == 32) {
			event.preventDefault();
		}
	}

	onPaste(event) {
		event.preventDefault();
	}

	register() {
		// this.showRegister = true;
		let patient = {};
		patient['patient_exists'] = false;
		patient['cid'] = this.mobilevalue;
		sessionStorage.setItem('cid', this.mobilevalue)
		sessionStorage.setItem('patientDetail', JSON.stringify(patient))
		this.router.navigate(['/bookappointment']);
	}

	navigate() {
		var num=document.getElementById("num") as HTMLInputElement;
		var no=num.value
		var regx =/^\d+$/;
		var text = no.toString();
		if (this.mobilevalue.toString().length == 10&&regx.test(text)) {
			this.router.navigate(['/bookappointment']);
		}
	}

	cancel() {
		this.showRegister = false;
	}

	get registerFormControl() {
		return this.registerPatient.controls;
	}

	bookappointment() {
		this.submitted = true;
		this.registerPatient.markAsTouched();
		if (this.registerPatient.invalid) {
			return;
		}
		const value = this.registerPatient.getRawValue();
		this._http.post(environment.BASE_URL + environment.HTTP_CONFIG.REGISTRATION_URL, value).then((result: any) => {
			console.log(result, 'success');

		})
		this.router.navigate(['/bookappointment']);
	}

	inputkeyUp(val) {
		console.log("Mobile number === ", val.target.value);
		console.log("Mobile length === ", val);
	}

	checkApponitmentExits(patient) {
		// console.log(patient);
		if (patient && patient.father_tel_number) {
			this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.MY_APPOINTMENTS, {
				params: {
					mode: AppConstants.APPOINTMENT.MODE_DATE,
					demail: AppConstants.COMMON_DMAIL,
					mobile: patient.father_tel_number,
					edate: moment(new Date()).format("YYYY-MM-DD")
				}
			}).then((response: any) => {
				console.log("response");

				console.log(response, response.mh, response.mh.length, response.mh[0].success);
				if (response && response.mh && response.mh.length > 0 && response.mh[0].success == 1) {
					console.log("list");

					const allAppointment = response['mh'][0]['appointments'][0];
					const currentDate = new Date();
					allAppointment.map((event: any) => {
						let eventDateFrmt = new Date(event.slot)
						console.log(eventDateFrmt.getTime(), currentDate.getTime())
						let futureAppointmentExits = eventDateFrmt.getTime() > currentDate.getTime();
						if (futureAppointmentExits) {
							this.router.navigate(['/appointment/list']);
							const futAppointList = JSON.stringify(response['mh'][0]['appointments'][0])
							sessionStorage.setItem(AppConstants.SESSION_KEY.APPOINTMENT_LIST, futAppointList)
						} else {
							sessionStorage.setItem(AppConstants.SESSION_KEY.APPOINTMENT_LIST, '')
							this.routeToBookAppointment(patient)
						}
					})
					// console.log(response['mh'][0]['appointments'][0][0])

				} else {
					this.routeToBookAppointment(patient)
				}
			})
		}
	}

	routeToBookAppointment(patient) {
		patient['patient_exists'] = true;
		sessionStorage.setItem('patientDetail', JSON.stringify(patient))
		sessionStorage.setItem('reg_patientDetail', '')
		this.router.navigate(['/bookappointment']);
	}
}