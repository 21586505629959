import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/constants/AppConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer  } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { sha512, sha512_224, sha512_256 } from 'js-sha512';
import { MatStepper } from '@angular/material';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})

export class PaymentComponent implements OnInit {
	paymentGateways: Array<any> = [];
	submitted = false;
	public payuform: any = {};
	orderDetails:Array<any>;
	disablePaymentButton: boolean = true;
	url: SafeResourceUrl;
	paymentEnabled: boolean = false;
	source;
	@ViewChild('paymentpage') paymentpage: ElementRef;
	receiveMessage: EventListener;
	appDetails;
	complaintDetails;
	identityCardDetails;
	patientDetails;
	newRegDetails: any;
	showMsg;
	msg = '';
	showError;
	requestUrl: String = "";
	@ViewChild('stepper') stepper: MatStepper;

	constructor(
		public router: Router,
		private _http: HttpService,
		private _fb : FormBuilder,
		private hostElement: ElementRef,
		private sanitizer: DomSanitizer) { 
			this.listenForFallbackRoutingEvents()
	}

	ngAfterViewInit() {
		this.stepper._getIndicatorType = () => 'number';
		this.stepper.selectedIndex = 2; 
	}

	async ngOnInit() {
		this.receiveMessage = (event: MessageEvent) => {
			console.log(event.origin);
			if (event.origin !== 'https://localhost:4200/call/video') {
				return;
			}
		}
		const iframe = this.hostElement.nativeElement.querySelector('iframe');
		console.log(iframe)

		const appoinmentJson = sessionStorage.getItem('booked_appoinment');
		this.appDetails = JSON.parse(appoinmentJson);
		// console.log(this.appDetails)
		console.log("Payment Details -- ", this.appDetails);

		const timeStamp = Date.now().toString();

		console.log(sha512('HXvbeheyyefhd+7yfxcdrue' + '|' + 
			sessionStorage.getItem('orderDetails') + '|' + 
			this.appDetails.cid + '|' + this.appDetails.amount + '|' + 
			timeStamp.slice(0, timeStamp.length - 3)));


		await this.showPaymentGateways();

		const newRegJson = sessionStorage.getItem('newReg');
		this.newRegDetails = JSON.parse(newRegJson);
		if(this.appDetails && isNullOrUndefined(this.appDetails.amount)) {
			this.appDetails.amount = this.newRegDetails.amount
			console.log("Payment Details -- ", this.appDetails.amount);
		} 
		console.log("Payment Details -- ", this.newRegDetails);

		const regPatientJSON = sessionStorage.getItem('reg_patientDetail');
		const exitsPat = sessionStorage.getItem('patientDetail');
		if (regPatientJSON) {
			this.patientDetails = JSON.parse(regPatientJSON);
			console.log("patient Details registered -- ", this.patientDetails);
		} else if (exitsPat) {
			this.patientDetails = JSON.parse(exitsPat);			
			console.log("existing patient Details -- ", this.patientDetails);
		}

		// await this.createOrder();
	}

	listenForFallbackRoutingEvents() {
	    // Create IE + others compatible event handler (2)
	    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
	    const eventer = window[eventMethod];
	    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

	    eventer(messageEvent, (e) => { 
	    	console.log("page redirects to --- ", e.data.navigateTo);
	      if (e.data.navigateTo) {
	        let url = e.data.navigateTo;
	    	console.log("page redirects to --- ", e.data.navigateTo);
	    	//this.router.navigateByUrl(url); (4)
	      }
	    }, false);
  	}

	onLoadFunc(paymentpage) {
		console.log("onLoadFunc called");
		//console.log('iframe link ************** ' , paymentpage.contentDocument.location.href);
    	this.source = paymentpage.contentWindow.location.href;
   		//console.log("iframe content document ",paymentpage.contentDocument.body );
		console.log("payment page link is : ", this.source);
  	}

	showPaymentGateways() {
		this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.PAYMENT_GATEWAYS).then((result: any) => {
			if (result) {
				console.log("payment gateway --- ", result)
				const gateways = result.mh ? (result.mh[0] ? result.mh[0].payment : []) : [];
				if (gateways && gateways.length > 0) {
					for (let index = 0; index < gateways.length; index++) {
						const element = gateways[index];
						// if (element.vendor_name == "Paytm") {
							this.paymentGateways.push(element);
							this.requestUrl = this.paymentGateways[index].request_url;
							console.log("this.request_url ", this.requestUrl)
						// }
					}
					return;
				}
				if (!this.paymentGateways || this.paymentGateways.length == 0) {
					//this.router.navigate(['/bookappointment']);
				}
			}
		})
	}

	getPaymentGateway(gate) {
		console.log(gate);
		console.log("Get result of gateway --- ", gate);
		this.requestUrl = gate.request_url;
		console.log("this.requestUrl = ", this.requestUrl)
	}

	getPaymentGateway1(gate, event) {
		console.log(gate);
		console.log(event);
		console.log("Get result of gateway --- ", gate, event);
		if (event === true) {
			this.requestUrl = gate.request_url;
			console.log("this.requestUrl = ", this.requestUrl)
		}
	}

	pay1() {
		const patientJSON = sessionStorage.getItem('booked_appoinment');
		const patientDetail = JSON.parse(patientJSON);
		if (patientDetail) {
			console.log("patientDetail  -  ", patientDetail)
			sessionStorage.setItem('thankyou', JSON.stringify(patientDetail));
			this.createOrder();
		} else {

		}
	}

	createOrder() {
		const timeStamp = Date.now().toString();
		const ip = sessionStorage.getItem("local_ip");
		console.log('timeStamp --- ', timeStamp.slice(0, timeStamp.length - 3))
		let time = timeStamp.slice(0, timeStamp.length - 3)
		sessionStorage.setItem('timeStamp', time);
		const complaintJson = sessionStorage.getItem('appoint_complaint_details');
		const complaintDetails = JSON.parse(complaintJson);
		console.log("complaintDetails Details -- ", complaintDetails);
		
		const identityJson = sessionStorage.getItem('appoint_identity_details');
		const identityDetails = JSON.parse(identityJson);
		console.log("identityDetails Details -- ", identityDetails);
		const params = {
			"mobile": this.appDetails.cid, //cus _mmob
			"total": this.appDetails.amount, // book_a
			"mode": AppConstants.APPOINTMENT.MODE_DATE,
			"timestamp": time,
			"details": "Video Call booking appointment for patient Name: " + this.patientDetails.full_name + " - MRID : " + this.appDetails.mrid,
			"session": ip, // Booking Appointment ID
			"pdate": this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '' 	// appoinment Id		
		}
		if (this.appDetails.new_patient === false) {
			let Order_Param = {
				mode: 1,
				mobile: this.appDetails.cid,
				total: this.appDetails.amount,
				timestamp: time,
				details: "p001X1,p002X2",
				session: ip,
				pdate: this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '', 	// appoinment Id		
				mr_id: this.appDetails.mrid,
				patient_email: this.appDetails.pemail,
				demail: this.appDetails.demail,
				doctor_id: this.appDetails.doctor_id,
				patient_name: this.patientDetails.full_name,
				symptoms: this.patientDetails.symptoms,
				mrn:this.patientDetails.mrn,
				age:this.patientDetails.age,
				booking_time: this.appDetails.btime,
				token: this.appDetails.btoken,
				event: this.appDetails.event,
				a_id: this.appDetails.a_id,
				user_type: 0,
				ref_id: "",
				complaint_attach: complaintDetails?String(complaintDetails.compliant_img):""
			}
			console.log("Order_Param Book_patient ", Order_Param)

			this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.ORDER_URL_3_1, Order_Param).then((result: any) => {
				if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
					console.log("Order Details -- ", result.mh[0].success)
					this.orderDetails = result.mh[0].orderid ? result.mh[0].orderid : "";
					sessionStorage.setItem('orderDetails', this.orderDetails.toString())
					this.redirectToPayment();
				}
			});
		} else {
			// For New Registered Patient 
			console.log("this.appDetails.new_patient ", this.appDetails.new_patient)
			let new_reg_params = {
				demail : this.appDetails.demail,
				fname : this.patientDetails.fname,
				lname : this.patientDetails.lname?this.patientDetails.lname:"",
				gender : this.patientDetails.gender,
				dob : this.patientDetails.dob,
				address : this.patientDetails.address?this.patientDetails.address:"",
				father : "",
				mother : "",
				phone1 : this.patientDetails.phone1,
				email : this.patientDetails.email?this.patientDetails.email:'',
				timestamp: time,
				identity_attach: identityDetails?identityDetails.identity_img:"",
				identity_id: identityDetails?identityDetails.identity_id:""
			}
			let Order_Param = {
				mode: 1,
				mobile: this.appDetails.cid,
				total: this.appDetails.amount,
				timestamp: time,
				details: "p001X1,p002X2",
				session: ip,
				pdate: this.appDetails.edate ? moment(this.appDetails.edate).format("DD-MM-YYYY") : '', 	// appoinment Id		
				mr_id: "",
				patient_email: "",
				demail: this.appDetails.demail,
				doctor_id: this.appDetails.doctor_id,
				patient_name: this.patientDetails.fname,
				booking_time: this.appDetails.btime,
				token: this.appDetails.btoken,
				event: this.appDetails.event,
				age:this.patientDetails.age,
				symptoms: this.patientDetails.symptoms,
				mrn:this.patientDetails.mrn,
				a_id: this.appDetails.a_id,
				user_type: 1,
				ref_id: "",
				complaint_attach: complaintDetails?String(complaintDetails.compliant_img):""
			}
			console.log("new_reg_params Book_patient ", new_reg_params);
			console.log("Order_Param Book_patient ", Order_Param);
			this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.REGISTRATION_URL_3_1, new_reg_params).then((result: any) => {
				if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
					console.log("Registered New patient Details -- ", result.mh[0].success)
					Order_Param['ref_id']= result.mh[0].ref_id;
					this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.ORDER_URL_3_1, Order_Param).then((result: any) => {
						if (result && result.mh && result.mh.length > 0 && result.mh[0].success == 1) {
							console.log("Order Details -- ", result.mh[0].success)
							this.orderDetails = result.mh[0].orderid ? result.mh[0].orderid : "";
							sessionStorage.setItem('orderDetails', this.orderDetails.toString())
							this.redirectToPayment();
						}
					});
				}
			});
		}
	}

	redirectToPayment() {
		const orderid = sessionStorage.getItem('orderDetails');
		let transactionID = this.appDetails.mrid + this.appDetails.sid;
		let orderID = orderid;
		let amount = this.appDetails.amount;
		// let amount = "1";
		let bName = this.patientDetails.full_name;
		let bAddress = "";
		let bCity = '';
		let bState = '';
		let bZip = "";
		let bCountry = 'India';
		let bMobile = this.appDetails.cid;
		let bEmail = this.appDetails.demail;
		let paytmData = {
			mobile: bMobile,
			amount: amount,
			email: bEmail,
			order_id: orderID,
			timestamp:sessionStorage.getItem('timeStamp')
		}
		const timeStamp = Date.now().toString();

		let params = {
			trID: this.appDetails.mrid + this.appDetails.sid,
			oID: orderid,
			key: sha512('HXvbeheyyefhd+7yfxcdrue' + '|' + sessionStorage.getItem('orderDetails') + '|' + this.appDetails.cid + '|' + parseInt(this.appDetails.amount) + '|' + sessionStorage.getItem('timeStamp')),
			b_name: this.patientDetails.full_name,
			b_address: "",
			b_city: "",
			b_state: "",
			b_zip: "",
			b_country: 'India',
			b_tel: this.appDetails.cid,
			b_email: this.appDetails.demail
		}

		sessionStorage.setItem('paytm_data', JSON.stringify(paytmData))
		let payTmlink = this.requestUrl + '?trID=' + transactionID + '&oID=' + orderID + '&key=' + sha512('HXvbeheyyefhd+7yfxcdrue' + '|' + sessionStorage.getItem('orderDetails') + '|' + this.appDetails.cid + '|' + parseInt(this.appDetails.amount) + '|' + sessionStorage.getItem('timeStamp')) + '&b_name=' + bName + '&b_address=' + bAddress + '&b_city=' + bCity + '&b_state=' + bState + '&b_zip=' + bZip + '&b_country=' + bCountry + '&b_tel=' + bMobile + '&b_email=' + bEmail;

		// // let payTmlink = this.requestUrl + '?trID=' + transactionID + '&oID=' + orderID + '&amount=' + amount + '&b_name=' + bName + '&b_address=' + bAddress + '&b_city=' + bCity + '&b_state=' + bState + '&b_zip=' + bZip + '&b_country=' + bCountry + '&b_tel=' + bMobile + '&b_email=' + bEmail;

		// //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(payTmlink);
		// //this.paymentEnabled = true; //enable iframe
		window.open(payTmlink, "_parent");
	}
}
