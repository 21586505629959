import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookappointmentRoutingModule } from './bookappointment-routing.module';
import { BookappointmentComponent, DialogDoctors } from "./bookappointment.component"
import { MyDatePickerModule } from 'mydatepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContent } from './bookterms.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule, MatDatepickerModule, MatDialogModule, MatIconModule, MatNativeDateModule, MatOptionModule, MatSelectModule, MatStepperModule } from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	declarations: [
		BookappointmentComponent,
		NgbdModalContent,
		DialogDoctors
	],
	imports: [
		CommonModule,
		// BrowserAnimationsModule,
		MyDatePickerModule,
		BookappointmentRoutingModule,FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatNativeDateModule, 
		MatOptionModule,
		MatSelectModule,
		MatIconModule,
		MatFormFieldModule,
		MatButtonModule,
		MatDatepickerModule,
		MatStepperModule,
        MatInputModule,
        MatButtonModule,
		MatAutocompleteModule,
		MatDialogModule,
    	NgbModule.forRoot()
	],
	entryComponents: [NgbdModalContent,DialogDoctors],
})

export class BookappointmentModule { }
