// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BASE_URL: 'https://128.199.159.151/helyxonapi/mh/api/v3.0',
  BASE_URL: 'https://epicare.net/helyxonapi/mh/api/v3.1',
  DOC_SEARCH_URL:'https://epicare.net/helyxonapi/mh/api/v3.1/doctorlist.php',
  BASE_URL3_1: 'https://epicare.net/helyxonapi/mh/api/v3.1',
  Loc_URL: 'https://epicare.net/helyxonapi/mh/api/v3.0/search_doctor.php',
  image_url: 'https://epicare.net/helyxonapi/cloud/image/image.php',
  doc_image_url:'https://epicare.net/helyxonapi/cloud/image/upload/',
  GET_REG:'https://epicare.net/helyxonapi/mh/api/v3.1/refer.php?ref',
  pdf_url: 'https://epicare.net/helyxonapi/cloud/pdf/pdf.php',
  IDENTITY_URL: 'https://epicare.net/helyxonapi/mh/api/v3.0/get_identity.php',
  IDENTITYPOST_URL: 'https://epicare.net/helyxonapi/mh/api/v3.0/identity_attach.php',
  COMPLAINT_URL: 'https://epicare.net/helyxonapi/mh/api/v3.0/complaint_attach.php',
  REG_POST_URL:'https://epicare.net/helyxonapi/mh/api/v3.1/reg_withaccess.php',
  HTTP_CONFIG: {
    REGISTRATION_URL_3_1: '/online_patient_register.php',
    ORDER_URL_3_1: '/order.php',
    REGISTRATION_URL: '/reg.php',
    COUNTRY_LIST: '?demail=rx119419@rxcarenet.org',
    BOOKING_APPOINMENT: '/online.php',
    // REGISTRATION_URL: '128.199.159.151/helyxonapi/mh/api/v3.0/reg.php',
    // PAYMENT_URL: '128.199.159.151/helyxonapi/mh/api/v3.0/paysettings.php',
    DOCTOR_URL: '/doctorlist.php?demail=rx119419@rxcarenet.org',
    CHANNEL_NAME:'videocall_rx3_rxcarenet_1',
    DEMAIL:"rx119419@rxcarenet.org",
    // PAYMENT_URL: 'https://helyxonapi.in/mh/api/v1.2/paysettings.php',
    PAY_URL: '/paysettings.php',
    PAYMENT_URL: '/paysettings.php',
    PAYMENT_GATEWAYS:'/agarwal_gateways.php',
    CREATE_ORDER:'/order.php',
    MY_APPOINTMENTS : '/searchappointments.php',
    DOCTOR_AVAILABILITY:'/webPatientAvailability.php',
    CREATE_VIDEO_ROOM:'/webCreateRoom.php',
    END_VIDEO_CALL:'/webEndCall.php',
    SEND_OTP:'/mobile_verify.php'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
