import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/constants/AppConstants';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
	selector: 'app-failure',
	templateUrl: './failure.component.html',
	styleUrls: ['./failure.component.scss']
})

export class FailureComponent implements OnInit {

	constructor(
		private router: Router,
		private _http: HttpService
	) { }

	ngOnInit() {
		// this.transactionComplete();
	}

	// transactionComplete() {
	// 	const transactionResult = sessionStorage.getItem('transaction_completed');
	// 	console.log("transactionResult", transactionResult);

	// 	if (transactionResult) {

	// 		const result = JSON.parse(transactionResult);
	// 		const paytmDataStr = sessionStorage.getItem('paytm_data');
	// 		let paytmData = JSON.parse(paytmDataStr);

	// 		if (paytmData.order_id == result.order_id) {

	// 			console.log("transaction error");
	// 			this.updateOrder(result.order_id, "Need to pay.", '');
	// 		} else {
	// 			this.router.navigate(["/findpage"])
	// 		}
	// 	}else{
 //      this.router.navigate(["/findpage"])
 //    }
	// }

	// updateOrder(orderId, status, transactionID) {
	// 	let params = {
	// 		"mode": AppConstants.ORDER.MODE,
	// 		"order_id": orderId,
	// 		"order_status": status,
	// 		"mer_trans_id": transactionID
	// 	}
	// 	this._http.post(environment.BASE_URL + environment.HTTP_CONFIG.CREATE_ORDER, params).then((result: any) => {
	// 		if (result) {
	// 			//this.orderDetails = result.mh ? (result.mh[0] ? result.mh[0].message : "") : "";
	// 			console.log("Update order result message", result.mh[0].message);
	// 		}
	// 	});
	// }

	redirectToHome(){
		this.router.navigate(["/findpage"])
	}
}
