export const AppConstants= {
    COMMON_DMAIL: 'rx119419@rxcarenet.org',
    NUMBER_SEARCH: {
        MODE: 5,
        DMAIL: "rx119419@rxcarenet.org"
    },
    // rx5924@rxcarenet.org
    TIME_SEARCH: {
    		MODE: 6,
    		DOCMAIL: "rx119419@rxcarenet.org",
    		DOC_ID: '',
    		BDATE: ''
    },
    APPOINTMENT: {
        MODE_ALL: 0,
        MODE_DATE:1
    },
    ORDER: {
        MODE: 2
    },
    SESSION_KEY: {
        APPOINTMENT_LIST: 'APPOINTMENT_LIST'
    },
    COUNTRY_Params: {
        demail: "rx119419@rxcarenet.org",
        mode: 0
    },
    STATE_Params: {
        demail: "rx119419@rxcarenet.org",
        mode: 5
    },
    CITY_Params: {
        demail: "rx119419@rxcarenet.org",
        mode: 2
    },
    LOC_Params: {
        demail: "rx119419@rxcarenet.org",
        mode: 3
    },
    LANGG_Params: {
        demail: "rx119419@rxcarenet.org",
        mode: 6,
    },
    // DOCTOR_Params : {
    //     demail: "rx119419@rxcarenet.org",
    //     mode: 4,
    //     param: "location:CHENNAI"
    // },
    DOCTOR_Params : {
        demail: "rx119419@rxcarenet.org",
        mode: 4,
    }
}