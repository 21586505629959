import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'ngbd-modal-content',
	template: `
    <div class="modal-header">
      <h4 class="modal-title"><b>Terms and Conditions</b></h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      	<p class="terms_para">By accessing, browsing, surfing and/or using <a href="https://www.dragarwal.com/" target="_blank">www.dragarwal.com</a> online payment gateway, you agree to the following terms. This page  contains the terms and conditions concerning your use of the <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> online payment website. By accessing this site, you agree to the following terms and conditions.</p>
	    <ul class="termsList">
	        <li>I/We agree the services provided by <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> at my/our
	            request to carry out the requested service payments through instructions on my/our account, given by me/us.
	        </li>
	        <li>The User/payer agrees that the debit/credit card details provided by him/ her for use of the aforesaid
	            Service(s) are correct and accurate and that the User/payer shall not use a debit/ credit card, that is not
	            lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The User/payer
	            further agrees and undertakes to provide accurate and valid debit/credit card details.</li>
	        <li><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> ls shall reasonably check the accuracy of the
	            information published on its site. Dr. Agarwal’s Eye Hospitals and any other name referred to on the
	            dragarwal.com website are intellectual properties of Dr. Agarwal’s Eye Hospitals.</li>
	        <li>The contents in the website of <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> cannot be
	            reproduced, modified, transferred, distributed, republished, downloaded, posted or transmitted in any form
	            or by any means including but not limited to electronic, mechanical photocopying or recording without the
	            prior written permission from Dr. Agarwal’s Eye Hospitals. You hereby agree that the material and content
	            herein within or provided by Dr. Agarwal’s Eye Hospitals website is for your own personal use only and may
	            not be used for commercial purposes or distributed commercially.</li>
	        <li>If your device does not support minimum requirement including but not limited to you may not be able to
	            use certain services or access certain information on the website.</li>
	        <li>We may suspend or change/alter any/ entire part of the service offered on the website without assigning
	            any reason, at any time.</li>
	        <li><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> and/or the Payment Service Providers shall
	            not be liable for any inaccurate, delay in (or) error (or), omission of any data, information or message, or
	            (b) the transmission or delivery of any such data, information or message; or (c) any loss or damage arising
	            from or occasioned by any such inaccuracy, error, delay or omission, non-performance or interruption in any
	            such data, information or message.</li>
	        <li><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> obligations, undertakings shall be subject to
	            the laws in force in India and nothing contained in these Terms and Conditions shall be in derogation of Dr.
	            Agarwal’s Eye Hospitals right to comply with any law enforcement agencies request or requirements relating
	            to any User’s use of the website or information provided to or gathered by Dr. Agarwals Eye Hospital Ltd /
	            Dr. Agarwals Health Care Ltd with respect to such use.</li>
	        <li><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> shall not be held liable in any nature for
	            any manner whatsoever.Server Slow Down / Session Timeout: In case the Website or Payment Service Provider’s
	            webpage, that is linked to the Website, is experiencing any server related issues like ‘slow down’ or
	            ‘failure’ or ‘session timeout’, the User shall, before initiating the second payment, kindly check the
	            original payment status with the user/ payer’s banker.In case the amount appears to be debited, ensure that
	            not to make the payment twice and immediately thereafter In case the amount is not debited, the User may
	            initiate a fresh transaction to make payment.</li>
	        <li><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> and the Payment Service Provider(s) assume no
	            liability whatsoever for any monetary or other damage suffered by the user on account of:the delay, failure,
	            interruption, or corruption of any data or other information transmitted in connection with use of the
	            Payment Gateway or Services in connection thereto; and/ or</li>
	        <li>Although all reasonable care has been taken to prevent unauthorized use of any information transmitted by
	            the User, <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> does not represent or guarantee that
	            the use of the Services provided by/ through it will not result in theft and/or unauthorized use of data
	            over the Internet.The User agrees that any communication sent by the User vide e-mail, shall imply release
	            of information therein/ therewith to Payment Gateway. The User agrees to be contacted via e-mail on such
	            mails initiated by him/ her.</li>
	    </ul>
	    <p class="terms_para"><b>Disclaimer–</b> The Service is provided in order to facilitate access to avail and pay the
	        services offered online. Dr. Agarwal’s Eye Hospitals or the Payment Service Provider(s) do not make any
	        representation of any kind, express or implied, as to the operation of the Payment Gateway other than what is
	        specified in the Website for this purpose. By accepting/ agreeing to these Terms and Conditions, the User
	        expressly agrees that his/ her use of the aforesaid online payment Service is entirely at own risk and
	        responsibility of the User.</p>
	    <p class="terms_para">At <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> (<a href="https://www.dragarwal.com/" target="_blank">www.dragarwal.com</a>), the
	        privacy of our visitors is of extreme importance to us. This privacy policy document outlines the type of
	        personal information that is received and collected by Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care
	        Ltd (<a href="https://www.dragarwal.com/" target="_blank">www.dragarwal.com</a>) and how it is used. This Privacy Policy demonstrates our firm commitment to privacy and
	        to disclose our practices in gathering, using and disclosing personal information through the hospital web site
	        unless required by law, regulation or court order. Personal information is information about you that is
	        personally identifiable like your name, address, email address, or phone number, and that is not otherwise
	        publicly available. This policy does not apply to the practices of companies that Dr. Agarwals Eye Hospital Ltd
	        / Dr. Agarwals Health Care Ltd does not own or control or to people that Dr. Agarwals Eye Hospital Ltd / Dr.
	        Agarwals Health Care Ltd does not employ or manage. Although there are links from this site to other web sites,
	        this Privacy Policy applies only to this site and not to web sites operated by others for example, if you click
	        on a link to another web site, the click takes you out of our site. We are not responsible for the content or
	        privacy practices of other web sites.</p>

	    <h4 class="terms_blue"><b>Information Sharing</b></h4>
	    <p class="terms_para"><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> does not rent, sell, or share
	        personal information about you with other people or non-affiliated companies except to provide products or
	        services you've requested, when we have your permission, or under the following circumstances:</p>
	    <p class="terms_para">1. We provide the information to trusted partners who work on behalf of or with Dr. Agarwals
	        Eye Hospital Ltd / Dr. Agarwals Health Care Ltd under confidentiality agreements. These companies may use your
	        personal information to help Dr. Agarwals Eye Hospital communicate with you about offers from Dr. Agarwals Eye
	        Hospital Ltd / Dr. Agarwals Health Care Ltd and our marketing partners. However, these companies do not have any
	        independent right to share this information.</p>
	    <p class="terms_para">2. We respond to subpoenas, court orders, or legal process, or to establish or exercise our
	        legal rights or defend against legal claims.</p>
	    <p class="terms_para">3. We believe it is necessary to share information in order to investigate, prevent, or take
	        action regarding illegal activities, suspected fraud, situations involving potential threats to the physical
	        safety of any person, violations of <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> terms of use,
	        or as otherwise required by law.</p>
	    <p class="terms_para">4. New categories of marketing communications may be added to this website from time to time.
	        Users who visit this website can opt out of receiving future marketing communications from these new categories
	        or they can unsubscribe by following instructions contained in the messages they receive.</p>
	    <p class="terms_para">5. <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> uses information for the
	        following general purposes: to customize the advertising and content you see, fulfil your requests for products
	        and services, improve our services, contact you, conduct research, and provide anonymous reporting for internal
	        and external clients.</p>
	    <h4 class="terms_blue"><b>Log Files</b></h4>
	    <p class="terms_para">Like many other web sites, <b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b>
	        (<a href="https://www.dragarwal.com/" class="terms_link" target="_blank">www.dragarwal.com</a>) makes use of log files. The information inside the log files includes internet protocol (IP)
	        addresses, type of browser, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and number
	        of clicks to analyze trends, administer the site, track user's movement around the site, and gather demographic
	        information. IP addresses and other such information are not linked to any information that is personally
	        identifiable. We protect the personal information we have collected from this site by using industry standard
	        security precautions against loss and unauthorized access, destruction, use, modification or disclosure of that
	        information.</p>

	    <h4 class="terms_blue"><b>Cookies and Web Beacons</b></h4>
	    <p class="terms_para"><b>Dr. Agarwals Eye Hospital Ltd / Dr. Agarwals Health Care Ltd</b> (<a href="https://www.dragarwal.com/" target="_blank">www.dragarwal.com</a>) does use
	        cookies to store information about visitors' preferences, record user-specific information on which pages the
	        user access or visit, customize web page content based on visitors' browser type or other information that the
	        visitor sends via their browser. We reserve the right to change or update these policies at any time upon
	        reasonable notice, effective immediately upon posting to this site.</p>

	    <h4 class="terms_blue"><b>Cancellation and Refund Policy</b></h4>
	    <p class="terms_para">i. In the event that, the Practitioner with whom User has booked a paid appointment via the
	        Website, has not been able to meet the User, User will need to write to us at support@dragarwal.com within five
	        (5) days from the occurrence of such event; in which case, the entire consultation amount as mentioned on the
	        Website will be refunded to the User within the next five (5) to six (6) business days in the original mode of
	        payment done by the User while booking. In case where the User, does not show up for the appointment booked with
	        a Practitioner, without canceling the appointment beforehand, the amount will not be refunded, and treated as
	        under Clause 3.4.6. However, where cancellation charges have been levied (as charged by the
	        Practitioner/Practice), you would not be entitled to complete refund even if you have canceled beforehand.</p>
	    <p class="terms_para">ii. Users will not be entitled for any refunds in cases where, the Practitioner is unable to
		        meet the User at the exact time of the scheduled appointment time and the User is required to wait, irrespective
		        of the fact whether the User is required to wait or choose to not obtain the medical services from the said
		        Practitioner.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary form-control" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `
})
export class NgbdModalContent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) {}
}