import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToasterModule } from 'angular2-toaster';
import { FailureComponent } from './failure/failure.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatAutocompleteModule, MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatOptionModule, MatSelectModule, MatStepperModule } from '@angular/material';
import { CarouselComponent } from './carousel/carousel.component';
import { FaqsComponent } from './faqs/faqs.component';

@NgModule({
  declarations: [FailureComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
		MatNativeDateModule, 
		MatOptionModule,
		MatSelectModule,
		MatIconModule,
		MatFormFieldModule,
		MatButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
		MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    ToasterModule.forRoot()
  ]
})
export class LayoutModule { }
