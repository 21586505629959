import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})

export class FaqsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  f1()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"

  // let i1=document.getElementById("i1") as HTMLElement;
  // i1.classList.add("fa-plus")
  }

  f2()
  {
  //  alert("hi")
  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f3()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f4()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f5()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f6()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f7()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f8()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f9()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c10=document.getElementById("collapseTen") as HTMLElement;
  // c10.className="collapse"
  }

  f10()
  {
  //  alert("hi")
  // let c2=document.getElementById("collapseTwo") as HTMLElement;
  // c2.className="collapse"

  // let c3=document.getElementById("collapseThree") as HTMLElement;
  // c3.className="collapse"

  // let c4=document.getElementById("collapseFour") as HTMLElement;
  // c4.className="collapse"

  // let c5=document.getElementById("collapseFive") as HTMLElement;
  // c5.className="collapse"

  // let c6=document.getElementById("collapseSix") as HTMLElement;
  // c6.className="collapse"

  // let c7=document.getElementById("collapseSeven") as HTMLElement;
  // c7.className="collapse"

  // let c8=document.getElementById("collapseEight") as HTMLElement;
  // c8.className="collapse"

  // let c1=document.getElementById("collapseOne") as HTMLElement;
  // c1.className="collapse"

  // let c9=document.getElementById("collapseNine") as HTMLElement;
  // c9.className="collapse"
  }
}
