import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

header :String = "Book Appointment";
@Output() Navigate = new EventEmitter();

navigateTo(element: string) {
  console.log(element)
  this.Navigate.emit(element)
}
  constructor(
    private _htpp :HttpService,
    public router: Router,
  ) { }

  ngOnInit() {
    this._htpp.headername.subscribe((data) => {
      console.log(data);
      if(data && data.header){
        this.header = data.header
      }else{
        console.log(this.router)
        this.header = "Book Appointment"
      }
  });
  }

  goto() {
    this.router.navigate(['/findpage']);
  }
}
