import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindpageRoutingModule } from './findpage-routing.module';
import { HttpService } from 'src/app/services/http/http.service';
import { MatAutocompleteModule, MatButtonModule, MatInputModule, MatStepperModule } from '@angular/material';
import { CarouselComponent } from '../carousel/carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FindpageRoutingModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    HttpService
  ]
})

export class FindpageModule { }
