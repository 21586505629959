import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {
  act1=true;
  act2=false;
  act3=false;
  li1;
  li2;
  li3;
  li1_class;
  li2_class;
  li3_class;
  constructor() { }

  ngOnInit() {
    this.li1=document.getElementById("li1") as HTMLElement
    this.li1_class=this.li1.className;
    console.log(this.li1_class);

    this.li2=document.getElementById("li2") as HTMLElement
    this.li2_class=this.li2.className;
    console.log(this.li2_class);

    this.li3=document.getElementById("li3") as HTMLElement
    this.li3_class=this.li3.className;
    console.log(this.li3_class);
  }
  f1(){
    this.act1=true;
    this.act2=false;
    this.act3=false;
   // alert("hi")
  // let c1=document.getElementById("c1") as HTMLImageElement;
 //  c1.src="assets/images/Page-1/Banner_nav_inactive@1x.png"
  }

  f2(){
    this.act1=false;
    this.act2=true;
    this.act3=false;
    // alert("hi")
 //   let c1=document.getElementById("c1") as HTMLImageElement;
 //  c1.src="assets/images/Page-1/Banner_nav_inactive@1x.png"
   }

   f3(){
    // alert("hi")
    this.act1=false;
    this.act2=false;
    this.act3=true;
   }

}
