import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AppConstants } from 'src/app/constants/AppConstants';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-thankyou',
	templateUrl: './thankyou.component.html',
	styleUrls: ['./thankyou.component.scss']
})

export class ThankyouComponent implements OnInit {
	appDetails;
	mrid;
	date;
	time;
	event;
	token;
	url: SafeResourceUrl;
	url1;
	slot;
	drname;
	ptname;
	isLoading: boolean = true;
	// sessionStorage: String = "";
	// bookAppError: any = "";

	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		private _http: HttpService
	) { }

	ngOnInit() {

		const appoinmentJson = sessionStorage.getItem('thankyou');
		//this.sessionStorage = appoinmentJson;
		this.appDetails = JSON.parse(appoinmentJson);
		console.log("details -- ", this.appDetails);

		const orderid = sessionStorage.getItem('orderDetails')
		console.log("orderDetails -- ", orderid);
		this.appDetails['order_id'] = orderid;
		// sessionStorage.removeItem('thankyou');

		// this.mrid = this.appDetails.mrid;
		// this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.appDetails.ref_link)
		// this.url1 = this.appDetails.ref_link;
		// console.log("details -- ", this.url);
		// this.slot = this.appDetails.slot;
		// console.log("Slet -- ", this.slot)
		// // let re = /\Z/gi;
		// // let result = this.appDetails.btime.replace(re, "");
		// // let frmt = new Date(result);
		// // console.log(result)
		// // frmt.setHours(frmt.getHours() - 5);
		// // frmt.setMinutes(frmt.getMinutes()-30);
		// // console.log(frmt)
		// this.time = sessionStorage.getItem('slotTime');
		// console.log(this.time)
		// this.event = this.appDetails.event;
		// this.date = moment(new Date(this.appDetails.edate)).format('DD-MM-YYYY');
		// // moment(new Date(year - value.dob, month, day)).format('YYYY-MM-DD')
		// this.token = this.appDetails.btoken;		
		if (this.appDetails == null) {
			this.router.navigate(["/findpage"])
			return;
		}
		this.newDetails(orderid);

		// this.registerPatient();
		// this.transactionComplete();
	}

	newDetails(orderid) {
		let params = {
			"mode": 0,
			"order_id": orderid
		}
		this._http.post(environment.BASE_URL3_1 + environment.HTTP_CONFIG.ORDER_URL_3_1, params).then((result: any) => {
			console.log("patient Details in thankyou -- ", result.mh[0]['order'])
			if (result && result.mh && result.mh[0]['success'] == 1) {
				console.log("patient Details in thankyou -- ", result.mh[0]['order'])
				var value =  result.mh[0]['order'];
				this.mrid = value[0].mr_id;
				this.slot = value[0].booking_time;
				this.token = value[0].token;
				this.url = this.sanitizer.bypassSecurityTrustResourceUrl(value[0].url);
				this.url1 = value[0].url;
				this.drname = value[0].doctor_name;
				this.ptname = localStorage.getItem('pt_name');
				// "order": [
				// {
				//    "mobile": "7358243600",
				//    "total": "5",
				//    "status": null,
				//    "date": "09-07-2020",
				//    "trackind_id": null,
				//    "mr_id": "MR4317",
				//    "token": "C3",
				//    "booking_time": "2020-07-09T11:30:00Z",
				//    "doctor_name": "Dr.Rajeev",
				//    "city": "Chennai",
				//    "url": "dev.epicare.net/v/consult.php/128ff2f1-60b0-4e8d-be79-3bd91e21b2c1"
				// }
				// ]
				this.isLoading = false;
			}
		});
	}

	transactionComplete() {
		const transactionResult = sessionStorage.getItem('transaction_completed');
		console.log("transactionResult", transactionResult);
		//this.sessionStorage = this.sessionStorage + "  Payment Detail- " + transactionResult;

		if (transactionResult) {

			const result = JSON.parse(transactionResult);
			const paytmDataStr = sessionStorage.getItem('paytm_data');
			let paytmData = JSON.parse(paytmDataStr);

			if (paytmData.order_id == result.order_id) {
				console.log("transaction success");
				this.registerPatient();
				this.updateOrder(result.order_id, 'success', result.txtn_id);
			} else {
				this.router.navigate(["/findpage"])
			}
		}
	}

	registerPatient() {
		if (this.appDetails.new_patient === false) {
			this.bookAppoinment();
		} else {
			const regPatientJSON = sessionStorage.getItem('reg_patientDetail');
			const registerPageDetails: any = JSON.parse(regPatientJSON);
			sessionStorage.removeItem('reg_patientDetail');
			console.log("registerPageDetails  -  ", registerPageDetails)
			if (registerPageDetails) {
				console.log("registerPageDetails  -  ", registerPageDetails)
				if (registerPageDetails.new_patient === true) {
					this._http.post(environment.BASE_URL + environment.HTTP_CONFIG.REGISTRATION_URL, registerPageDetails).then((respone: any) => {
						let mrid = "", pemail = "";
						console.log('New register success - ', respone, respone.mh[0]['account']['Demographics']['Record']['mr_number']);
						if (respone && respone.mh && respone.mh[0]['account'] && respone.mh[0]['account']['Demographics'] && respone.mh[0]['account']['Demographics']['Record']) {
							console.log('New register success - ', respone, respone.mh[0]['account']['Demographics']['Record']['mr_number']);
							mrid = respone.mh[0]['account']['Demographics']['Record']['mr_number']
							// mh[0].account.Account_info.email
							pemail = respone.mh[0]['account']['Account_info']['email'];
						}
						registerPageDetails['full_name'] = registerPageDetails['fname'] + ' ' + registerPageDetails['lname'];
						registerPageDetails['new_reg'] = true;
						const regDataJSON = sessionStorage.getItem('newReg');
						const regDetails: any = JSON.parse(regDataJSON);
						sessionStorage.removeItem('newReg');
						const orderid = sessionStorage.getItem('orderDetails');
						let params = {
							demail: registerPageDetails.demail,
							cid: regDetails.cid,
							event: regDetails.event,
							edate: regDetails.edate,
							sid: Date.now(),
							operator: regDetails.operator,
							cid_e164: regDetails.cid,
							btoken: regDetails.btoken,
							btime: regDetails.btime,
							pemail: pemail,
							mrid: mrid,
							doctor_id: regDetails.doctor_id,
							amount: regDetails.amount,
							order_id: orderid
						}
						this.appDetails = params;
						this.bookAppoinment();
					})
				}
			}
		}
	}

	updateOrder(orderId, status, transactionID) {
		let params = {
			"mode": AppConstants.ORDER.MODE,
			"order_id": orderId,
			"order_status": status,
			"mer_trans_id": transactionID
		}
		this._http.post(environment.BASE_URL + environment.HTTP_CONFIG.CREATE_ORDER, params).then((result: any) => {
			if (result) {
				console.log("Update order result message", result.mh[0].message);
			}
		});
	}

	bookAppoinment() {
		const complaintJson = sessionStorage.getItem('appoint_complaint_details');
		const complaintDetails = JSON.parse(complaintJson);
		console.log("complaintDetails Details -- ", complaintDetails);
		console.log("appDetails Details -- ", this.appDetails);

		this._http.get(environment.BASE_URL + environment.HTTP_CONFIG.BOOKING_APPOINMENT, { params: this.appDetails }).then((result: any) => {

			if (result.mh[0].success != 0) {
				console.log("book app ---- ", result.mh[0]);
				var res = JSON.parse(result.mh[0].token)[0];
				const refLink = result.mh[0].ref_link;
				this.mrid = this.appDetails.mrid;
				this.url = this.sanitizer.bypassSecurityTrustResourceUrl(refLink)
				this.url1 = refLink;
				console.log("Complaint app_id --- ", result.mh[0].id);
				if (complaintDetails) {
					complaintDetails['app_id'] = res.id;
					complaintDetails['patient_email'] = this.appDetails.pemail;
					console.log("complaintDetails Details -- ", complaintDetails);
					this._http.post(environment.COMPLAINT_URL, complaintDetails).then((complaint_result: any) => {
						console.log("complaint_result Details -- ", complaint_result);
					})
				}

				this.slot = res.slot;
				this.time = sessionStorage.getItem('slotTime');

				this.event = this.appDetails.event;
				this.date = moment(new Date(this.slot)).format('DD-MM-YYYY');
				this.token = this.appDetails.btoken;
			} else {
				console.log("book app error");
				console.log(result);
			}
			//this.bookAppError = result;
			this.isLoading = false;
		}).catch((error) => {
			console.log("book app error");
			console.log(error);
			this.router.navigate(["/findpage"])
		})
	}
}
