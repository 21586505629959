// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PubNubAngular } from 'pubnub-angular2';
import { TwilioService } from './services/twilio.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FindpageComponent } from './layout/findpage/findpage.component';
import { FindpageModule } from './layout/findpage/findpage.module';
// import { BookappointmentComponent } from './layout/bookappointment/bookappointment.component';
// import { ThankyouComponent } from './layout/thankyou/thankyou.component';
// import { ThankyouModule } from './layout/thankyou/thankyou.module';
// import { BookappointmentModule } from './layout/bookappointment/bookappointment.module';
import { LayoutModule } from './layout/layout.module';
import { LayoutComponent } from './layout/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { HttpModule } from '@angular/http';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatOptionModule, MatSelectModule, MatStepperModule, MAT_DATE_LOCALE } from '@angular/material';
import { CarouselComponent } from './layout/carousel/carousel.component';
import { FaqsComponent } from './layout/faqs/faqs.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FindpageComponent,
    LayoutComponent,
    CarouselComponent,
    FaqsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FindpageModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    AmazingTimePickerModule,
    HttpModule, 
    ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatNativeDateModule, 
		MatOptionModule,
		MatSelectModule,
		MatIconModule,
		MatFormFieldModule,
		MatButtonModule,
		MatDatepickerModule,
  ],
  providers: [PubNubAngular, TwilioService,    {provide: MAT_DATE_LOCALE, useValue:'en-GB'}
],
  bootstrap: [AppComponent]
})
export class AppModule { }
