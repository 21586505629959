import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @ViewChild("caroussel", { }) caroussel;
  @ViewChild("faqs", { }) faqs;
  @ViewChild("footer", { }) footer;

  showFooter: boolean = true;
  element;
  constructor(
    private _http: HttpService,
    private router:Router,
    private vps: ViewportScroller
  ) { }

  ngOnInit() {  
  }

  home(){
   // alert("hi")
   this.router.navigate(['/'])
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  } 

  navigateTo(element: string) {
    console.log(element);
  //  this.vps.scrollToAnchor(element);
    //this[element].nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  title = 'appointmentapp';
}
